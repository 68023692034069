import React, { useState } from 'react';
import { Typography, Box, Grid, Button } from '@mui/material';
import { addressColors } from '../../AddChild/mockedData';
import styles from '../../AddChild/styles.module.scss';
import { useUpdateProfileMutation } from '../../../services/apis/ProfileService';
import { toast } from 'react-toastify';

export const EditProfileColor = ({
  setStepNo,
  handleClose,
  childDetail,
  refetchChildren,
}) => {
  const [selectedColor, setSelectedColor] = useState(
    childDetail.profileCardColor
  );
  const [UpdateProfile] = useUpdateProfileMutation();

  const updateBackgroundColor = async () => {
    const reqBody = { ...childDetail };
    reqBody.profileCardColor = selectedColor;
    const response = await UpdateProfile(reqBody);
    if (response.data) {
      refetchChildren();
      toast.success('Background Color updated successfully!', {
        autoClose: 1000,
      });
      handleClose();
    } else if (response.error) {
      toast.error('Failed to update details!');
    }
  };
  return (
    <Box display="flex" flexDirection="column" gap="9px">
      <Typography
        color={'black'}
        fontSize="28px"
        fontWeight="700"
        textAlign="center"
      >
        Select Background Color
      </Typography>
      <Grid xs={12} mt={3} className={styles.addressContainer}>
        {addressColors.map((color, index) => (
          <Box
            key={index}
            className={styles.colors}
            backgroundColor={color.color}
            position="relative"
            onClick={() => setSelectedColor(color.color)}
          >
            {selectedColor === color.color && <img src="/check.png" alt="" />}
          </Box>
        ))}
      </Grid>
      <Box mt={4}>
        <Button
          disabled={selectedColor === ''}
          variant="contained"
          sx={{
            backgroundColor: 'primary.main',
            color: 'white',
            py: 1,
            borderRadius: 5,
            width: '100%',
            mt: '15px',
          }}
          onClick={updateBackgroundColor}
        >
          Save
        </Button>
      </Box>
      <Box
        color="#4B3E3D"
        pt={2}
        display="flex"
        justifyContent={'center'}
        sx={{ cursor: 'pointer' }}
        onClick={() => setStepNo(1)}
      >
        <Box component="img" mr="10px" src="/back.svg" alt="back"></Box>{' '}
        <span style={{ fontSize: '12px', color: '#4B3E3D', fontWeight: '500' }}>
          Back
        </span>
      </Box>
    </Box>
  );
};
