import { useEffect, useRef, useState } from 'react';
import { Avatar, Box } from '@mui/material';

import { skinColors } from './mockedData';

const DefaultAvatar = ({ isSelected, avatarPathsList = [], setValue }) => {
  const [showList, setShowList] = useState(false);
  const [activeAvatar, setActiveAvatar] = useState(avatarPathsList[0]);
  const [selectedSkinTone, setSelectedSkinTone] = useState(1000);

  const avatarRef = useRef();

  const onClickListAvatar = (path, i) => {
    setSelectedSkinTone(i);
    const img = new Image();
    img.src = path;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/jpeg');
      canvas.toBlob(setValue, 'image/jpeg');
      setActiveAvatar(dataURL);
      setShowList(false);
    };
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (avatarRef.current && !avatarRef.current.contains(event.target)) {
        setShowList(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="avatarUpload">
      <Avatar
        alt="male avatar"
        src={activeAvatar}
        sx={{
          cursor: 'pointer',
          width: '48px',
          height: '48px',
          border: `${isSelected ? '2px solid ' : '1px solid '}`,
          borderColor: `${isSelected ? 'primary.main' : 'borderLight.main'}`,
        }}
        onClick={() => {
          setShowList(true);
        }}
      />
      {showList && (
        <Box
          ref={avatarRef}
          position="absolute"
          bgcolor="textWhite.main"
          zIndex={1}
          display="flex"
          gap="5px"
          p="5px 10px"
          boxShadow="1px 1px 10px 1px"
          borderRadius="10px"
          justifyContent="space-evenly"
          marginTop="2px"
          sx={{ cursor: 'pointer' }}
        >
          {avatarPathsList.map((avatarPath, i) => (
            <Avatar
              key={avatarPath}
              sx={{
                border: '2px solid',
                borderColor: selectedSkinTone === i ? 'primary.main' : 'none',
                width: 30,
                height: 30,
                backgroundColor: skinColors[i].color,
              }}
              alt={`avatar-${i}`}
              onClick={() => onClickListAvatar(avatarPath, i)}
            >
              {' '}
            </Avatar>
          ))}
        </Box>
      )}
    </div>
  );
};

export default DefaultAvatar;
