import axios from 'axios';

export const verifyReCaptcha = async (token) => {
  try {
    const response = await axios.post(
      'https://twedcknvqf.execute-api.eu-west-2.amazonaws.com/stag/verify-recaptcha',
      {
        token,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          accept: '*/*',
        },
      }
    );

    return response;
  } catch (error) {
    return error.response;
  }
};
