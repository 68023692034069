import { Navigate } from 'react-router-dom';

import Login from '../auth/Login';
import SignUp from '../auth/SignUp';
import ForgotPassword from '../auth/ForgotPassword';
import ResetPassword from '../auth/ResetPassword';

const AUTH_ROTUES = [
  {
    path: '',
    element: <Navigate to="/login" />,
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'sign-up',
    element: <SignUp />,
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: 'reset-password',
    element: <ResetPassword />,
  },
  {
    path: '*',
    element: <Navigate to="/login" />,
  },
];

export default AUTH_ROTUES;
