export const tabStyles = {
  '&.Mui-selected': {
    backgroundColor: '#50A7DA',
    color: 'white',
    borderRadius: '60px',
    fontWeight: '590',
    // margin: "2px",
  },
  fontWeight: '590',
  color: '#676767',
  fontSize: '1rem',
  padding: '11px 19px',
  minHeight: '42px',
  maxHeight: '42px',
  textTransform: 'none',
};

export const primaryButton = {
  background: '#F39200',
  color: 'white',
  borderRadius: '32px',
  maxWidth: '200px',
  width: '50%',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: '#F39200',
    color: 'white',
  },
};
