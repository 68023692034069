import EditIcon from '@mui/icons-material/Edit';
import { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';

import Navbar from '../../common/components/Navbar/Navbar';
import Footer from '../../common/components/Footer/Footer';
import CustomTabs from './CustomTabs';
import dashBoardLogo from '../../assets/Images/dashboardLayout.png';
import mobileLogo from '../../assets/Images/phone-icon.png';
import mailLogo from '../../assets/Images/mail-icon.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Colors from '../../theme/KidzCubicleTheme/Colors';
import { useGetAccountDetails } from '../../common/hooks/useGetAccountDetails';
import dayjs from 'dayjs';
import { getProfileImage } from '../../common/utils/getProfileImage';
import { uploadProfileImage } from '../../common/utils/uploadProfileImage';
import { toast } from 'react-toastify';

const tabHeaders = ['Profiles', 'Packages', 'Settings', 'Bookings'];

const tabs = [
  { index: 0, path: '/user/dashboard/' },
  { index: 1, path: '/user/dashboard/packages/' },
  { index: 2, path: '/user/dashboard/settings/' },
  { index: 3, path: '/user/dashboard/bookings/' },
  { index: 4, path: '/user/dashboard/order/' },
  { index: 5, path: '/user/dashboard/addChild/' },
];

const DashboardLayout = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [currentTab, setCurrentTab] = useState(0);
  const location = useLocation();
  const { account } = useGetAccountDetails();
  const navigate = useNavigate();

  const [image, setImage] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);

  useEffect(() => {
    getProfileImage()
      .then(setImage)
      .catch(() => {});
  }, []);

  useEffect(() => {
    setCurrentTab(
      tabs.find((tab) => tab.path.includes(location.pathname)).index
    );
  }, [location.pathname]);

  const handleOnChange = (event) => {
    const newImage = event.target?.files?.[0];
    if (newImage) {
      if (image) {
        URL.revokeObjectURL(image);
      }
      setUploadingImage(true);
      uploadProfileImage(newImage)
        .then(() => {
          setImage(URL.createObjectURL(newImage));
        })
        .catch(() => {
          toast.error('Failed to upload image!');
        })
        .finally(() => {
          setUploadingImage(false);
        });
    }
  };

  const handleChangeTabValue = (event, newValue) => {
    const nextTab = tabs.find((tab) => tab.index === newValue);

    navigate(`${nextTab.path}`);
    setCurrentTab(newValue);
  };

  return (
    <>
      <Box display={'flex'} minHeight="100vh" flexDirection={'column'}>
        <Navbar />
        <Box>
          <img
            width={'100%'}
            height={matches && 200}
            src={dashBoardLogo}
            alt=""
          />
          <Box px={matches ? 2 : 4}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={3}>
                <Box
                  display={'flex'}
                  justifyContent="center"
                  position={'sticky'}
                  top={'15%'}
                >
                  <Box
                    maxWidth={matches ? '306px' : '100%'}
                    width="100%"
                    mt={-10}
                    p={4}
                    alignItems={'center'}
                    display={'flex'}
                    justifyContent="center"
                    flexDirection={'column'}
                    borderRadius="0.5rem"
                    bgcolor={'#FFFFFF'}
                    boxShadow="0px 1px 4px 0px #00000029"
                  >
                    <Box style={{ borderRadius: 999, position: 'relative' }}>
                      <Avatar
                        alt="Profile Picture"
                        src={image}
                        sx={{
                          height: '94px',
                          width: '94px',
                          border: 'solid 2px #F39200',
                        }}
                      />
                      <IconButton
                        style={{
                          position: 'absolute',
                          borderRadius: 999,
                          bottom: '4px',
                          right: '0px',
                          height: '32px',
                          width: '32px',
                          background: 'white',
                          border: 'solid 2px #F39200',
                        }}
                      >
                        <label
                          htmlFor={'profile-pic-selector-button'}
                          className="cursor-pointer"
                        >
                          {uploadingImage ? (
                            <CircularProgress
                              size={'16px'}
                              sx={{ display: 'flex' }}
                            />
                          ) : (
                            <EditIcon
                              sx={{
                                height: '22px',
                                width: '22px',
                                display: 'flex',
                              }}
                            />
                          )}
                          <input
                            id="profile-pic-selector-button"
                            accept="image/jpeg,image/jpg"
                            type="file"
                            name={'profileImage'}
                            hidden
                            disabled={uploadingImage}
                            onChange={handleOnChange}
                          />
                        </label>
                      </IconButton>
                    </Box>
                    <Box mt={1}>
                      <Typography
                        color={Colors.DARK_BLACK}
                        fontSize={23}
                        fontWeight={'bold'}
                      >
                        {account
                          ? `${account?.accountFirstName} ${account?.accountLastName}`
                          : ''}
                      </Typography>{' '}
                    </Box>
                    <Box mt={1}>
                      <Typography
                        color={Colors.LIGHT_BLACK}
                        fontSize={'16px'}
                        fontWeight={'400'}
                        display={'flex'}
                        alignItems="center"
                        gap="5px"
                      >
                        <img
                          src={mobileLogo}
                          style={{ width: '16.57px', height: '16.61px' }}
                          alt="mobile"
                        />
                        <span>{account?.phoneNumber}</span>
                      </Typography>
                    </Box>
                    <Box mt={1}>
                      <Typography
                        color={Colors.LIGHT_BLACK}
                        fontSize={'16px'}
                        fontWeight={'400'}
                        display={'flex'}
                        alignItems="center"
                        gap="5px"
                      >
                        <img
                          src={mailLogo}
                          style={{ width: '20px', height: '16px' }}
                          alt="mobile"
                        />
                        <span>{account?.email}</span>
                      </Typography>
                    </Box>
                    <Box mt={5}>
                      <Typography
                        fontSize={'11px'}
                        sx={{ color: '#00000099' }}
                        fontWeight={'400'}
                      >
                        Member Since :{' '}
                        {dayjs(account?.timestamp).format('MMM DD, YYYY')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={9} mt={matches ? 1 : 3}>
                <Box
                  pb={2}
                  display={'flex'}
                  justifyContent={matches && 'center'}
                >
                  <CustomTabs
                    tabsList={tabHeaders}
                    tabValue={currentTab}
                    onTabChange={handleChangeTabValue}
                  />
                </Box>
                <Outlet />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default DashboardLayout;
