import BUTTON from './button';

export const components = {
  MuiInputBase: {
    styleOverrides: {
      root: {
        height: '3rem',
        borderRadius: '0.5rem!important',
        color: '#909090',
        fontFamily: 'SF Pro Display',
      },
    },
  },
  MuiButton: BUTTON,
};
