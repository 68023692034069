import React, { useState, useEffect } from 'react';
import { ArrowBack } from '@material-ui/icons';
import { validateNumber } from '../../common/utils/Validation';
import { MuiOtpInput } from 'mui-one-time-password-input';
import SubTitle from '../../common/components/SubTitle';
import Title from '../../common/components/Title';
import {
  Grid,
  Box,
  Alert,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import { formatTimer } from '../../utils';

import { validateRequired } from '../../common/utils/Validation';
import VerifyOtpLogo from '../../assets/Images/forgotPassword.svg';
import { confirmSignUp, signIn } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import Colors from '../../theme/KidzCubicleTheme/Colors';
export const VerifySignupOtp = ({ formik, setStep }) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [verificationErrorMessage, setVerificationErrorMessage] = useState('');
  const [timer, setTimer] = useState(120);
  const [isLoading, setIsLoading] = useState(false);

  const handleEnterOtp = () => {
    setIsLoading(true);
    setVerificationErrorMessage('');
    confirmSignUp({ username: formik.values.email, confirmationCode: otp })
      .then((result) => {
        setIsLoading(true);
        signIn({
          username: formik.values.email,
          password: formik.values.password,
        })
          .then(() => {
            navigate('/login');
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((error) => {
        setVerificationErrorMessage(error.message || JSON.stringify(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleResendClick = () => {
    try {
      setTimer(120);

      formik.handleSubmit(formik.values);
    } catch (error) {
      console.error('Error in handleResendClick:', error);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <Grid container className="VerifyOtp--form-container">
      <Grid className="VerifyOtp__title" item xs={12}>
        <Box textAlign={'center'}>
          <img src={VerifyOtpLogo} alt="forgot-password" />
          <Box my={1}>
            <Title name="Enter One Time Password" />
          </Box>
          <SubTitle name="We have sent an OTP to your registered email" />
        </Box>
        <Typography color={'lightBlue.main'} textAlign={'center'} mt={2}>
          {timer > 0 ? formatTimer(timer) : '0:00'}
        </Typography>
      </Grid>

      <Grid className="VerifyOtp__form" item xs={12}>
        <MuiOtpInput
          value={otp}
          onChange={setOtp}
          onComplete={setOtp}
          length={6}
          autoFocus
          validateChar={validateNumber}
          TextFieldsProps={{}}
        />
      </Grid>
      <Grid className="VerifyOtp__buttons" item xs={12}>
        {validateRequired(verificationErrorMessage) && timer > 0 && (
          <Grid item xs={12} mb={2}>
            <Alert severity="error">Please enter valid OTP</Alert>
          </Grid>
        )}
        {timer < 0 && (
          <Typography
            color={'#FF0000D9'}
            fontWeight={'500'}
            textAlign={'center'}
            mb={1}
          >
            Timed out. Please click Resend OTP to get new code
          </Typography>
        )}
        <Button
          className="Common__Login-btn"
          fullWidth
          color="primary"
          variant="contained"
          sx={{ color: Colors.WHITE_COLOR }}
          disabled={otp.length < 6 || timer < 0}
          onClick={handleEnterOtp}
        >
          {!isLoading ? 'Verify' : <CircularProgress size={24} />}
        </Button>
        <Box marginTop={2}>
          <Button
            variant="outlined"
            sx={{
              width: '100%',
              borderRadius: '32px',
              height: '42px',
            }}
            type="submit"
            onClick={handleResendClick}
          >
            Resend OTP
          </Button>
        </Box>

        <Typography
          className="VerifyOtp__back-to-btn"
          onClick={() => setStep(0)}
        >
          <ArrowBack />
          <span>Back</span>
        </Typography>
      </Grid>
    </Grid>
  );
};
