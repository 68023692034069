import React from 'react';
import { Button, Box, Typography } from '@mui/material';

import { no_packages } from '../../../shared/images';
import { primaryButton } from '../styles/styles';
import { useNavigate } from 'react-router-dom';
const NoPackage = () => {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <img src={no_packages} alt="no time slot" />
      <Typography>
        Not Enough Minutes Left Kindly Purchase A Package.
      </Typography>

      <Button
        onClick={() => {
          navigate('/user/dashboard/packages');
        }}
        sx={primaryButton}
        textTransform="capitalize"
      >
        Explore Packages
      </Button>
    </Box>
  );
};

export default NoPackage;
