import Box from '@mui/material/Box';

import { SvgIcon } from '@mui/material';

const PublicLogo = () => (
  <Box>
    <SvgIcon sx={{ height: '95px', width: '95px' }}>
      <svg
        width="95"
        height="68"
        viewBox="0 0 95 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M66.0206 47.1446C62.7157 47.3575 58.5685 47.3738 58.8323 45.1265C59.2234 41.7708 61.6511 41.6134 64.956 41.3982C68.2609 41.1852 71.2556 42.2938 71.1237 44.0712C70.7904 48.605 69.3231 46.9317 66.0206 47.1446Z"
          fill="#7CB92B"
        />
        <path
          d="M51.7421 48.1879C48.5461 48.3939 44.5307 48.4008 44.7806 46.1559C45.1533 42.8024 47.5023 42.6474 50.6984 42.4414C53.8945 42.2354 56.7966 43.3509 56.674 45.126C56.3592 49.6575 54.9382 47.9819 51.7421 48.1879Z"
          fill="#7CB92B"
        />
        <path
          d="M38.3706 49.4114C35.3527 49.6058 31.5642 49.5989 31.791 47.354C32.1289 44.0028 34.346 43.857 37.3639 43.6626C40.3818 43.4682 43.1219 44.593 43.0132 46.3681C42.7354 50.8972 41.3862 49.217 38.3706 49.4114Z"
          fill="#7CB92B"
        />
        <path
          d="M25.7396 50.6246C22.93 50.8052 19.4006 50.782 19.602 48.5394C19.9029 45.1906 21.9672 45.054 24.7768 44.8735C27.5864 44.693 30.1438 45.8293 30.0512 47.6044C29.8128 52.1313 28.5492 50.4441 25.7396 50.6246Z"
          fill="#7CB92B"
        />
        <path
          d="M15.2285 48.2216L69.4348 43.4495V63.1074L15.2285 65.4935V48.2216Z"
          fill="#C1D10C"
        />
        <path
          d="M69.4316 43.4495L78.888 46.9487V65.4148L69.4316 63.1074V43.4495Z"
          fill="#7CB92B"
        />
        <path
          d="M78.8911 65.4147L69.4649 62.936L15.2285 65.4934L26.5224 67.3194L78.8911 65.4147Z"
          fill="#36A938"
        />
        <path
          d="M20.2754 57.2599C20.2754 55.221 21.8191 53.5223 23.9529 53.3996C25.11 53.3325 26.0867 53.7722 26.5565 54.3115C26.7347 54.5082 26.8319 54.7512 26.8319 54.9433C26.8319 55.4802 26.4176 55.8759 25.8807 55.906C25.4942 55.9292 25.3021 55.7325 25.0545 55.5404C24.8069 55.3483 24.4759 55.1608 23.9529 55.1909C22.9762 55.2464 22.2171 56.1166 22.2171 57.1488C22.2171 58.181 22.9739 58.9656 23.9529 58.9077C24.4342 58.8799 24.793 58.667 25.0406 58.4472C25.2882 58.2273 25.4942 57.9797 25.8807 57.9588C26.4176 57.9288 26.8319 58.2898 26.8319 58.8267C26.8319 59.0188 26.7347 59.2595 26.5565 59.477C26.089 60.0695 25.1378 60.6319 23.9529 60.7013C21.8191 60.8194 20.2754 59.2965 20.2754 57.2599Z"
          fill="white"
        />
        <path
          d="M28.125 54.1237C28.125 53.5868 28.5508 53.1355 29.0901 53.1054C29.6293 53.0753 30.0552 53.478 30.0552 54.0149V57.1833C30.0552 58.0789 30.606 58.5279 31.2933 58.4885C31.9807 58.4492 32.5338 57.9354 32.5338 57.0398V53.8714C32.5338 53.3345 32.9596 52.8832 33.4989 52.8531C34.0358 52.8231 34.464 53.2257 34.464 53.7627V56.8616C34.464 58.8033 33.073 60.178 31.2956 60.2798C29.5182 60.3817 28.1273 59.1666 28.1273 57.2249V54.1237H28.125Z"
          fill="white"
        />
        <path
          d="M37.9979 59.2062C37.8868 59.6112 37.5165 59.9213 37.0744 59.9468C36.5375 59.9769 36.1094 59.5742 36.1094 59.0373V51.0482C36.1094 50.5113 36.5352 50.06 37.0744 50.0299C37.6114 49.9998 38.0395 50.4025 38.0395 50.9394V53.2538C38.5903 52.8094 39.2777 52.5224 40.0368 52.4785C41.9785 52.3674 43.4389 53.8948 43.4389 55.9338C43.4389 57.9727 41.9785 59.6668 40.0368 59.7779C39.2661 59.8218 38.5626 59.6135 37.9979 59.2062ZM41.4971 56.0448C41.4971 54.9849 40.7403 54.2281 39.7614 54.286C38.8102 54.3415 38.0673 55.1538 38.0395 56.1744V56.3272C38.0673 57.3594 38.8102 58.0606 39.7614 58.0051C40.738 57.9472 41.4971 57.1187 41.4971 56.0448Z"
          fill="white"
        />
        <path
          d="M44.6777 50.4215C44.6777 49.8152 45.173 49.2921 45.7794 49.2574C46.3857 49.2227 46.881 49.6902 46.881 50.2966C46.881 50.9029 46.3857 51.426 45.7794 51.4607C45.173 51.4954 44.6777 51.0279 44.6777 50.4215ZM44.8166 53.1687C44.8166 52.6317 45.2424 52.1804 45.7817 52.1503C46.3186 52.1203 46.7467 52.523 46.7467 53.0599V58.4314C46.7467 58.9684 46.3209 59.4197 45.7817 59.4497C45.2447 59.4798 44.8166 59.0771 44.8166 58.5402V53.1687Z"
          fill="white"
        />
        <path
          d="M48.1211 55.6652C48.1211 53.6263 49.6648 51.9275 51.7986 51.8049C52.9557 51.7378 53.9324 52.1775 54.4022 52.7167C54.5804 52.9134 54.6776 53.1565 54.6776 53.3485C54.6776 53.8855 54.2633 54.2812 53.7264 54.3113C53.3399 54.3344 53.1478 54.1377 52.9002 53.9456C52.6526 53.7536 52.3216 53.5661 51.7986 53.5962C50.8196 53.6517 50.0628 54.5219 50.0628 55.5541C50.0628 56.5863 50.8196 57.3709 51.7986 57.313C52.2799 57.2852 52.6387 57.0723 52.8863 56.8524C53.1339 56.6326 53.3399 56.3849 53.7264 56.3641C54.2633 56.334 54.6776 56.6951 54.6776 57.232C54.6776 57.4241 54.5804 57.6648 54.4022 57.8823C53.9347 58.4748 52.9835 59.0372 51.7986 59.1066C49.6624 59.2246 48.1211 57.7018 48.1211 55.6652Z"
          fill="white"
        />
        <path
          d="M55.9863 49.912C55.9863 49.3751 56.4122 48.9238 56.9514 48.8937C57.4906 48.8636 57.9165 49.2663 57.9165 49.8032V57.7923C57.9165 58.3292 57.4906 58.7805 56.9514 58.8106C56.4122 58.8407 55.9863 58.438 55.9863 57.901V49.912Z"
          fill="white"
        />
        <path
          d="M59.291 55.0242C59.291 52.9853 60.8347 51.2866 62.9685 51.1639C64.0979 51.0991 64.9519 51.518 65.336 51.8536C65.5559 52.0341 65.6809 52.3026 65.6809 52.578C65.6809 52.8117 65.6114 53.0223 65.4194 53.2676L63.642 55.4894C63.4499 55.7208 63.2416 55.8296 62.9801 55.8435C62.457 55.8736 62.0566 55.5241 62.0566 55.015C62.0566 54.809 62.1122 54.5984 62.2626 54.4248L63.4476 52.9946C63.3503 52.9598 63.1166 52.9436 62.9662 52.9529C61.9872 53.0084 61.2304 53.8786 61.2304 54.9108C61.2304 55.943 61.9733 56.7276 62.9662 56.6697C63.4753 56.6396 63.8202 56.4151 64.0539 56.2092C64.3293 55.9592 64.5214 55.7417 64.894 55.7208C65.4309 55.6908 65.8452 56.0518 65.8452 56.5887C65.8452 56.7808 65.748 57.0215 65.5698 57.239C65.1023 57.8315 64.1511 58.3939 62.9662 58.4633C60.8462 58.586 59.291 57.077 59.291 55.0242Z"
          fill="white"
        />
        <path
          d="M90.4195 15.2405L74.4529 7.54999C73.0411 6.86957 71.4998 7.6495 71.0092 9.32739C70.5046 11.0493 71.2776 12.8938 72.6894 13.5719L82.9743 18.5245L67.4312 28.5062C68.063 26.634 68.2667 24.5187 67.9774 22.2552C66.9244 13.9954 59.8124 7.17738 50.4672 6.80014L43.0243 6.49928C41.2492 6.42754 39.9624 7.80688 40.1846 9.54494L40.3883 11.1488C39.8513 10.3989 38.9765 9.90598 37.9628 9.89672C36.2086 9.88052 34.6487 11.3131 34.5214 13.0604L33.2046 31.1284C33.0056 30.853 32.7556 30.603 32.4293 30.3832L20.0106 22.2876L26.7245 6.93206C27.0971 6.08502 27.2244 5.28426 27.0138 4.35158C26.5787 2.43995 24.7689 1.5119 22.9266 2.25711C21.9824 2.63898 21.0914 3.48139 20.6563 4.5159L12.4219 23.3777C12.2182 23.8591 12.0794 24.4145 12.0771 25.0116L9.17952 12.299C8.72129 10.2902 6.73559 9.27185 4.80081 10.0564C2.86371 10.8387 1.63943 13.1553 2.09536 15.1664L8.46439 43.1143C8.92263 45.1255 10.9083 46.1414 12.8454 45.3592C14.7825 44.5746 16.0068 42.2603 15.5486 40.2492L12.4543 26.671C12.7413 27.2241 13.137 27.5828 13.4865 27.8212L28.0737 37.2891C28.9832 37.8839 30.0756 37.9256 31.1147 37.5044C31.7928 37.229 32.3784 36.78 32.8343 36.2245L32.7533 37.3354C32.626 39.0827 33.9753 40.5431 35.7295 40.5593C37.4838 40.5755 39.0436 39.1406 39.1709 37.3956L40.8326 14.6133L43.1585 32.8433C43.3807 34.5814 45.0354 36.0787 46.8082 36.1505L54.2511 36.4513C57.4449 36.5809 60.1804 35.9283 62.3559 34.6832C62.6035 35.6552 63.2168 36.5069 64.0685 36.9188L81.2246 45.181C82.6364 45.8614 84.1661 45.1231 84.6683 43.4036C85.1613 41.7257 84.3999 39.8372 82.9881 39.1568L71.4396 33.5955L90.7897 21.2115C91.3753 20.8042 91.8729 20.2603 92.102 19.485C92.6273 17.6798 91.6808 15.8492 90.4195 15.2405ZM53.4897 30.5243L49.2938 30.3554L47.0258 12.5651L51.224 12.7341C56.8061 12.9586 60.641 17.0341 61.2728 21.9891C61.9 26.9441 59.0719 30.7488 53.4897 30.5243Z"
          stroke="#E94E1B"
          strokeWidth="3.72408"
          strokeMiterlimit="10"
        />
        <path
          d="M90.4195 15.2405L74.4529 7.54999C73.0412 6.86957 71.4998 7.6495 71.0092 9.32739C70.5046 11.0493 71.2776 12.8938 72.6894 13.5719L82.9743 18.5245L67.4312 28.5062C68.063 26.634 68.2667 24.5187 67.9774 22.2552C66.9244 13.9954 59.8124 7.17738 50.4672 6.80014L43.0243 6.49928C41.2492 6.42754 39.9624 7.80688 40.1846 9.54494L40.3883 11.1488C39.8513 10.3989 38.9765 9.90597 37.9628 9.89672C36.2086 9.88052 34.6487 11.3131 34.5214 13.0604L33.2046 31.1284C33.0056 30.853 32.7556 30.603 32.4293 30.3832L20.0106 22.2876L26.7245 6.93206C27.0971 6.08502 27.2244 5.28426 27.0138 4.35158C26.5787 2.43995 24.7689 1.5119 22.9266 2.25711C21.9824 2.63898 21.0914 3.48139 20.6563 4.5159L12.4219 23.3777C12.2182 23.8591 12.0794 24.4145 12.0771 25.0116L9.17952 12.299C8.72129 10.2902 6.73559 9.27185 4.80081 10.0564C2.86371 10.8387 1.63943 13.1553 2.09536 15.1664L8.46439 43.1143C8.92263 45.1255 10.9083 46.1414 12.8454 45.3592C14.7825 44.5746 16.0068 42.2603 15.5486 40.2492L12.4543 26.671C12.7413 27.2241 13.137 27.5828 13.4865 27.8212L28.0737 37.2891C28.9832 37.8839 30.0756 37.9256 31.1147 37.5044C31.7928 37.229 32.3784 36.78 32.8343 36.2245L32.7533 37.3354C32.626 39.0827 33.9753 40.5431 35.7295 40.5593C37.4838 40.5755 39.0436 39.1406 39.1709 37.3956L40.8326 14.6133L43.1585 32.8433C43.3807 34.5814 45.0354 36.0787 46.8082 36.1505L54.2511 36.4513C57.4449 36.5809 60.1804 35.9283 62.3559 34.6832C62.6035 35.6552 63.2168 36.5069 64.0685 36.9188L81.2246 45.181C82.6364 45.8614 84.1661 45.1231 84.6684 43.4036C85.1613 41.7257 84.3999 39.8372 82.9881 39.1568L71.4396 33.5955L90.7897 21.2115C91.3753 20.8042 91.8729 20.2603 92.102 19.485C92.6273 17.6798 91.6808 15.8492 90.4195 15.2405ZM53.4897 30.5243L49.2938 30.3554L47.0258 12.5651L51.224 12.7341C56.8061 12.9586 60.641 17.0341 61.2728 21.9891C61.9 26.9441 59.0719 30.7488 53.4897 30.5243Z"
          fill="#F39200"
        />
      </svg>
    </SvgIcon>
  </Box>
);

export default PublicLogo;
