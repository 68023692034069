export const BASE_URL = 'https://stag.api.kidzcubicle.com/uaps/api/v1';
export const VERSION = '1.0';
export const DATE_FORMAT = 'D MMM YYYY, hh:mm:ss A';
export const SHORT_DATE_FORMAT = 'D MMM YYYY';
export const BACKGROUND_COLORS = [
  '#4FC3F7',
  '#FF69B4',
  '#00CED1',
  '#F8BBD0',
  '#87CEEB',
  '#A6C9E2',
  '#f195b5',
];
export const BIRTHDAY_PARTY_PACKAGE = {
  id: 6,
  packageName: 'Birthday Party Pack',
  packageDescription:
    "Make your child's birthday unforgettable with KidzCubicle's stress-free party package.",
  price: 230,
  minutes: 120,
  highlights1: '15-20 kids',
  highlights2: 'Goodie Bags',
  highlights3: '',
  mostPopular: false,
  timestampAdded: '2024-03-22T00:00:00',
  timestampUpdated: '2024-03-22T00:00:00',
  addedBy: 'system',
  updatedBy: 'system',
  features: ['15-20 kids', 'Goodie Bags'],
};
