import { Box, Skeleton } from '@mui/material';
import React from 'react';

const ProfileSkeleton = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width={'350px'}
      p={2}
      boxShadow={1}
      borderRadius={4}
    >
      <Box display="flex" alignItems="center">
        <Skeleton variant="circular" width={40} height={40} />
        <Box ml={2}>
          <Skeleton variant="text" width={100} />
          <Skeleton variant="text" width={80} />
        </Box>
      </Box>
      <Skeleton variant="circular" width={10} height={10} />
    </Box>
  );
};

export default ProfileSkeleton;
