import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BASE_URL } from '../common/utils/Constants';

import { fetchAuthSession } from 'aws-amplify/auth';
import { signOut } from 'aws-amplify/auth';

const baseQuery = fetchBaseQuery({
  baseUrl: `${BASE_URL}/`,
  prepareHeaders: async (headers, { getState }) => {
    try {
      const res = await fetchAuthSession();
      if (!res.userSub) throw new Error('Unauthenticated');
      {
        const token = res.tokens?.accessToken?.toString();
        headers.set('Authorization', `Bearer ${token}`);
      }
    } catch (e) {
      await signOut();
      window.location.href = '/login';
    }
    return headers;
  },
});

const baseQueryWithErrorHandling = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  // if (result?.error && result?.error?.originalStatus !== 200) {
  //   toast.error(result?.error?.data?.error || 'Something went wrong!');
  // }
  if (result.error?.status === 401 || result.error?.status === 403) {
    // api.dispatch(clearToken());
    // await signOut();
    // window.location.href = '/login';
  }
  return result;
};

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithErrorHandling,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [],
  endpoints: () => ({}),
});

export default api;
