export const switchStyle = {
  marginRight: '19px',
  width: 51,
  height: 31,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: '2px',

    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      paddingLeft: '6px',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#2ECA45',
        opacity: 1,
        border: 0,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 27,
    height: 27,
  },
  '& .MuiSwitch-track': {
    borderRadius: '40px',
    backgroundColor: '#E9E9EA',
    opacity: 1,
  },
};
