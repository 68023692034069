import React from 'react';
import { no_time_slot } from '../../../shared/images';
import { Typography, Box } from '@material-ui/core';
const NoTimeSlot = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <img src={no_time_slot} alt="no time slot" />
      <Typography> Sorry, please select other date.</Typography>
    </Box>
  );
};

export default NoTimeSlot;
