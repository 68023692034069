import api from '../api';

export const dashboardApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAccountDetailsBySubId: build.query({
      query: (subId) => ({
        url: `accounts/subId/${subId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetAccountDetailsBySubIdQuery } = dashboardApi;

export const { endpoints } = dashboardApi;
