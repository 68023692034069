import React from 'react';
import { Box, Typography } from '@mui/material';

export const CartPopupItem = ({ packageItem, index }) => {
  return (
    <Box
      px={3}
      py={2}
      boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
      borderRadius={'10px'}
    >
      <Box
        mb={2}
        backgroundColor={index % 2 === 0 ? 'primary.main' : 'lightBlue.main'}
        py={0.5}
        width="100%"
      />

      <Box sx={{ fontFamily: 'SF Pro Display' }} textAlign={'left'}>
        <Typography color={'black.light'} fontSize={'23px'} fontWeight={'600'}>
          {packageItem.packageName}
        </Typography>
        <Typography color={'#6F6F6F'} fontSize={'16px'} fontWeight={'400'}>
          {packageItem.packageDescription}
        </Typography>
        <Typography fontSize={'33px'} fontWeight={600} color={'primary'}>
          £{packageItem.price}
        </Typography>
      </Box>
    </Box>
  );
};
