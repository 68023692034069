import React, { useState } from 'react';
import { Button, Box, Typography } from '@mui/material';

const ActivitySelector = ({ activities, onSelectActivity }) => {
  const [selected, setSelected] = useState('');

  const handleSelection = (event, activity) => {
    setSelected(event.target.value);
    onSelectActivity(activity);
  };

  const selectorButtonStyle = (selectedValue) => {
    return {
      backgroundColor:
        Number(selectedValue) === Number(selected) ? '#50A7DA' : '#fff',
      color: Number(selectedValue) === Number(selected) ? 'white' : '#4B3E3D',
      fontSize: '1rem',
      minWidth: '127px',
      height: '46px',
      borderRadius: '60px',
      border: '1px solid #B1B1B1',
      '&:hover': {
        backgroundColor: '#50A7DA',
        color: 'white',
      },
      textTransform: 'capitalize',
      padding: '10px 16px 10px 16px',
    };
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      sx={{
        maxWidth: {
          xs: '341px',
          sm: '399px',
          lg: '505px',
        },
      }}
    >
      <Typography
        textAlign={'left'}
        fontSize={'1.2rem !important'}
        my={'16px !important'}
        fontWeight={'700 !important'}
        color={'#4B3E3D !important'}
      >
        Select Activity
      </Typography>

      {activities?.length > 0 ? (
        <Box
          aria-label="selection buttons"
          display={'flex'}
          flexWrap={'wrap'}
          gap={2}
        >
          {activities.map((activity) => (
            <Button
              onClick={(event) => handleSelection(event, activity)}
              value={activity.id}
              sx={selectorButtonStyle(activity.id)}
              key={activity.id}
            >
              {activity.activity.activityName}
            </Button>
          ))}
        </Box>
      ) : (
        <Typography>No Activities Are Available</Typography>
      )}
    </Box>
  );
};

export default ActivitySelector;
