import { useEffect, useState, useCallback } from 'react';
import { useLazyGetAccountDetailsBySubIdQuery } from '../../services/apis/DashboardService';
import { fetchAuthSession } from 'aws-amplify/auth';

export const useGetAccountDetails = () => {
  const [account, setAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [trigger, setTrigger] = useState(0);

  const [getAccountDetailsBySubId] = useLazyGetAccountDetailsBySubIdQuery();

  useEffect(() => {
    (async () => {
      setIsLoading(false);
      try {
        const session = await fetchAuthSession();
        const accountRes = await getAccountDetailsBySubId(
          session.userSub
        ).unwrap();
        setAccount(accountRes);
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [getAccountDetailsBySubId, trigger]);

  const refetchAccount = useCallback(() => {
    setTrigger((prev) => prev + 1);
  }, []);

  return { account, isLoading, error, refetchAccount };
};
