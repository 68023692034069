import React from 'react';
import { Grid, Typography } from '@mui/material';

const TransactionCard = ({ title, payment, date }) => {
  return (
    <Grid
      boxShadow={'0px 1.27px 2.55px 0px #00000040'}
      height={'60px'}
      borderRadius={'5px'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      maxWidth={'700px'}
    >
      <Grid item xs={4}>
        <Typography
          color={payment !== 'Amount' ? 'primary.main' : '#000000B2'}
          fontSize={'1rem'}
          marginLeft={'19px'}
          textAlign={'left'}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography textAlign={'center'} color={'#000000B2'} fontSize={'1rem'}>
          {payment}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography textAlign={'center'} color={'#000000B2'} fontSize={'1rem'}>
          {date}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TransactionCard;
