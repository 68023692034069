import { Box, Typography } from '@mui/material';

import InputTitle from './InputTitle';
import ErrorIcon from '@mui/icons-material/Error';

const Input = ({
  disabled = false,
  maxLength = 100,
  placeholder,
  type = 'text',
  label,
  name,
  value,
  handleChangeEvent,
  handleBlurEvent,
  error,
  touched,
  noHeight,
  isRequired = false,
  endAdornment,
  ...props
}) => {
  return (
    <>
      <InputTitle label={label} isRequired={isRequired} />
      <Box
        sx={{
          position: 'relative',
          height: noHeight ? '' : '70px',
          width: '100%',
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <Box
            maxLength={maxLength}
            component="input"
            disabled={disabled}
            name={name}
            sx={{
              marginTop: '5px',
              // textTransform: `${name === "dob" ? "uppercase" : ""}`,
              borderRadius: '8px',
              padding: '15px 21px',
              paddingRight: `${isRequired && error && touched ? '50px' : ''}`,
              outline: 'none',
              fontSize: '15px',
              width: '100% !important',
              border: '1px solid ',
              borderColor: 'borderLight.main',
              fontFamily: 'SF Pro Display',
              textAlign: 'left',
              '&:hover': {
                borderColor: 'borderLight.light',
              },
              '&:focus': {
                borderColor: 'black.main',
              },
              ...(error &&
                touched && {
                  borderColor: 'richRed.main',
                }),
            }}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={handleChangeEvent}
            onBlur={handleBlurEvent}
            {...props}
          />
          {endAdornment && (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                marginTop: '2.5px',
              }}
            >
              {endAdornment}
            </Box>
          )}
        </Box>
        {error && touched ? (
          <Typography
            sx={{
              color: 'richRed.main',
              fontWeight: '600',
              fontSize: '12px',
              marginTop: '1px',
              // textTransform: "capitalize",
            }}
          >
            {error}
          </Typography>
        ) : (
          ''
        )}
        <Box position={'absolute'} top={'16px'} right={'14px'}>
          {error && isRequired && touched && name === 'dob' && (
            <ErrorIcon color="error"></ErrorIcon>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Input;
