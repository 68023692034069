import { Navigate } from 'react-router-dom';

import DashboardLayout from '../layouts/DashboardLayout';
import Packages from '../features/Packages';
import AddChild from '../features/AddChild';
import Order from '../features/Order/index';
import Dashboard from '../features/Dashboard/index';
import Payment from '../features/Payment/Payment';
import Bookings from '../features/Bookings';
import BookingAfterPayment from '../features/Bookings/BookNow/BookingAfterPayment';
import Settings from '../features/Settings';

const USER_ROUTES = [
  {
    path: '',
    element: <Navigate to="dashboard" />,
  },
  {
    path: 'dashboard/order',
    element: <Order />,
  },
  {
    path: 'dashboard/payment',
    element: <Payment />,
  },
  {
    path: 'dashboard/payment/booking',
    element: <BookingAfterPayment />,
  },
  {
    path: 'dashboard/addChild',
    element: <AddChild />,
  },

  {
    path: 'dashboard',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
      {
        path: 'packages',
        element: <Packages />,
      },
      {
        path: 'bookings',
        element: <Bookings />,
      },
      {
        path: 'settings',
        element: <Settings />,
      },
    ],
  },
];

export default USER_ROUTES;
