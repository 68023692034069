import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Colors from '../../theme/KidzCubicleTheme/Colors';
import GoogleLogo from '../../assets/Images/google.svg';
import FacebookLogo from '../../assets/Images/facebook.svg';
import { signInWithRedirect } from 'aws-amplify/auth';

import { Grid } from '@mui/material';

const SocialSignInLoginBtns = ({ OnError, isSignUp }) => {
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [isFacebookLoading, setIsFacebookLoading] = useState(false);

  const googleLogin = () => {
    setIsGoogleLoading(true);
    signInWithRedirect({ provider: 'Google' }).catch((error) => {
      OnError(error.message || JSON.stringify(error));
      setIsGoogleLoading(false);
    });
  };

  const facebookLogin = () => {
    setIsFacebookLoading(true);
    signInWithRedirect({ provider: 'Facebook' })
      .then((r) => {
        console.log('r', r);
      })
      .catch((error) => {
        OnError(error.message || JSON.stringify(error));
      })
      .finally(() => {
        setIsFacebookLoading(false);
      });
  };

  return (
    <Grid container columnGap={2} rowGap={1} justifyContent={'center'}>
      <Grid item xs={12} md={5}>
        <Button
          fullWidth
          variant="raised"
          onClick={googleLogin}
          className="Common__Login-btn"
          disabled={isGoogleLoading || isFacebookLoading}
          sx={{
            color: Colors.GREY_COLOR_1,
            background: Colors.GREY_COLOR_2,
          }}
        >
          {!isGoogleLoading ? (
            <Box className="Login__google-btn">
              <img src={GoogleLogo} alt="Google" height={25} width={25} />
              Google Sign {isSignUp ? 'up' : 'in'}
            </Box>
          ) : (
            <CircularProgress size={24} />
          )}
        </Button>
      </Grid>
      <Grid item xs={12} md={5}>
        <Button
          fullWidth
          variant="variant"
          onClick={facebookLogin}
          className="Common__Login-btn"
          disabled={isGoogleLoading || isFacebookLoading}
          sx={{
            color: Colors.LIGHT_BLUE,
            background: 'none',
            border: '1px solid' + Colors.LIGHT_BLUE,
            margin: '1rem 0',
          }}
        >
          {!isFacebookLoading ? (
            <Box className="Login__google-btn">
              <img src={FacebookLogo} alt="Facebook" height={25} width={25} />
              Facebook Sign {isSignUp ? 'up' : 'in'}
            </Box>
          ) : (
            <CircularProgress size={24} />
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

SocialSignInLoginBtns.propTypes = {
  OnError: PropTypes.func.isRequired,
};

export default SocialSignInLoginBtns;
