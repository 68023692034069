import { Box, Button, Typography } from '@mui/material';

import { useUpdateProfileMutation } from '../../../services/apis/ProfileService';

const DeleteProfileModal = ({ handleClose, childDetail, refetchChildren }) => {
  const [UpdateProfile] = useUpdateProfileMutation();

  const onDeleteBtnClick = async () => {
    const response = await UpdateProfile({
      ...childDetail,
      status: 'INACTIVE',
    });
    if (response.data) {
      refetchChildren();
      handleClose();
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap="9px">
      <Typography fontSize="18px" fontWeight="500" textAlign="center">
        Do you really want to delete the profile?
      </Typography>
      <Box
        mt="20px"
        display="flex"
        flexDirection="row"
        width="90%"
        justifyContent="center"
        gap="20px"
        mx="auto"
      >
        <Button
          onClick={onDeleteBtnClick}
          variant="contained"
          color="error"
          sx={{
            backgroundColor: '#FF5733',
            color: 'white',
            borderRadius: '50px',
            width: '239px',
            fontSize: '13px',
          }}
        >
          Delete
        </Button>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="error"
          sx={{
            color: 'error',
            borderRadius: '50px',
            width: '239px',
            fontSize: '13px',
          }}
        >
          No
        </Button>
      </Box>
    </Box>
  );
};

export default DeleteProfileModal;
