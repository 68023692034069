import React from 'react';
import EditTextField from '../../../common/components/EditTextField';
import { Box, Button, Typography } from '@mui/material';
import { ArrowBack } from '@material-ui/icons';
import { setCurrentOtpStep } from '../../../store/slices/SettingSlice';
import { useDispatch } from 'react-redux';
import { useGetAccountDetails } from '../../../common/hooks/useGetAccountDetails';
const ProfileDetails = () => {
  const dispatch = useDispatch();
  const { account } = useGetAccountDetails();
  const onEditClick = () => {
    dispatch(setCurrentOtpStep(2));
  };
  const handleBackClick = () => {
    dispatch(setCurrentOtpStep(0));
  };

  function getFullName(firstName, lastName) {
    if (lastName) {
      return `${firstName} ${lastName}`;
    } else {
      return firstName;
    }
  }
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={2}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Typography fontSize={'1.8rem'} color={'#4B3E3D'}>
        Profile Details
      </Typography>
      <EditTextField
        backgroundColor={'#ECECEC'}
        label={'Name'}
        value={getFullName(account?.accountFirstName, account?.accountLastName)}
        isEditable={false}
      />

      <EditTextField
        backgroundColor={'#ECECEC'}
        label={'Email'}
        value={account?.email}
        isEditable={false}
      />
      <EditTextField
        backgroundColor={'#EEF6FB'}
        label={'Phone Number'}
        value={account?.phoneNumber}
        isEditable={true}
        onEditClick={onEditClick}
      />

      <Button sx={{ mt: '20px' }} variant="primary" onClick={handleBackClick}>
        Save
      </Button>
      <Box
        className="cursor-pointer"
        display={'flex'}
        gap={1}
        onClick={handleBackClick}
      >
        <ArrowBack />
        <Typography>Back</Typography>
      </Box>
    </Box>
  );
};

export default ProfileDetails;
