import api from '../api';

export const promoCodeAPi = api.injectEndpoints({
  endpoints: (build) => ({
    validatePromoCode: build.mutation({
      query: (code) => ({
        url: `promo-codes/validate/${code}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useValidatePromoCodeMutation } = promoCodeAPi;

export const { endpoints } = promoCodeAPi;
