import api from '../api';

export const proceedOrderApi = api.injectEndpoints({
  endpoints: (build) => ({
    confirmOrder: build.mutation({
      query: (orderDetail) => ({
        url: 'payments/intent',
        method: 'POST',
        body: orderDetail,
      }),
    }),
    makePayment: build.mutation({
      query: (makePaymentDetail) => ({
        url: 'payments/confirm-payment',
        method: 'POST',
        body: makePaymentDetail,
      }),
    }),
    getAllocationStatus: build.query({
      query: (id) => ({
        url: `payments/account/allocation-status-false/${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useConfirmOrderMutation,
  useMakePaymentMutation,
  useGetAllocationStatusQuery,
} = proceedOrderApi;
export const { endpoints } = proceedOrderApi;
