import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { validateEmail } from '../../common/utils/Validation';
import Title from '../../common/components/Title';
import forgotPasswordLogo from '../../assets/Images/forgotPassword.svg';
import './ForgotPassword.scss';
import '../../styles/common/Common.scss';
import SubTitle from '../../common/components/SubTitle';
import Colors from '../../theme/KidzCubicleTheme/Colors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { validateRequired } from '../../common/utils/Validation';
import Alert from '@mui/material/Alert';
import { resetPassword } from 'aws-amplify/auth';

import Input from '../../common/components/Input';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);

  const handleNextStep = async () => {
    setIsLoading(true);
    try {
      await resetPassword({ username: email });
      setStep(1);
    } catch (error) {
      setErrorMessage(error.message || JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Grid container className="ForgotPassword--form-container">
        <Grid className="ForgotPassword__title" item xs={12}>
          <Box textAlign={'center'}>
            <img src={forgotPasswordLogo} alt="forgot-password" />
            <Box my={2}>
              <Title name="Forgot Password" />
            </Box>
            {step === 0 && (
              <SubTitle name="Enter your registered email address, and we'll send you a password reset link." />
            )}
            {step === 1 && (
              <SubTitle name="The password reset link has been sent to your registered email address; it should arrive in the next few minutes. If not, please check whether you have entered the correct email address or if you have used federated accounts such as Google or Facebook to sign in." />
            )}
          </Box>
        </Grid>
        {step === 0 && (
          <>
            <Grid className="ForgotPassword__form" item xs={12}>
              <Box>
                <Input
                  label="Email"
                  type="text"
                  name="email"
                  placeholder={'xyz@gmail.com'}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={
                    email.length > 0 && !validateEmail(email)
                      ? 'Enter a valid email'
                      : ''
                  }
                  isRequired
                  touched
                  noHeight
                />
              </Box>
            </Grid>
            {validateRequired(errorMessage) && (
              <Grid item xs={12} mb={2}>
                <Alert severity="error">{errorMessage}</Alert>
              </Grid>
            )}
            <Grid className="ForgotPassword__buttons" item xs={12}>
              <Button
                className="Common__Login-btn"
                fullWidth
                color="primary"
                variant="contained"
                sx={{ color: Colors.WHITE_COLOR }}
                disabled={
                  email.length === 0 || !validateEmail(email) || isLoading
                }
                onClick={handleNextStep}
              >
                {!isLoading ? 'Next' : <CircularProgress size={24} />}
              </Button>
              <Typography
                className="ForgotPassword__back-to-btn"
                onClick={() => {
                  navigate('/login', { replace: true });
                }}
              >
                <ArrowBackIcon fontSize="small" mx={2} />
                <span>Back to Log in</span>
              </Typography>
            </Grid>
          </>
        )}
        {step === 1 && (
          <Grid className="ForgotPassword__buttons" item xs={12}>
            <Typography
              className="ForgotPassword__back-to-btn"
              onClick={() => {
                navigate('/login', { replace: true });
              }}
            >
              <ArrowBackIcon fontSize="small" mx={2} />
              <span>Back to Log in</span>
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ForgotPassword;
