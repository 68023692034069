import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography, Alert } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';

import OrderCard from '../Order/OrderCard';
import StripeForm from './StripeForm';

import './Payment.scss';
import Layout from '../../layouts/Layout/Layout';

const Payment = () => {
  loadStripe.setLoadParameters({ advancedFraudSignals: false });
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const navigate = useNavigate();

  const [isPaymentFailed, setPaymentFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const items = useSelector((state) => state.cart).items;
  const { clientSecret } = useSelector((state) => state.cart);

  const totalMinutes = items?.reduce((acc, curr) => acc + curr?.minutes, 0);
  const isBirthdayPackSelected = items?.find(
    (item) => item?.packageName === 'Birthday Party Pack'
  );

  useEffect(() => {
    if (items.length <= 0) {
      navigate('/user/dashboard/packages');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      {isPaymentFailed && (
        <Box mt="20px" maxWidth="90%" mx="auto">
          <Alert
            variant="filled"
            severity="error"
            onClose={() => setPaymentFailed(false)}
          >
            {errorMessage}
          </Alert>
        </Box>
      )}
      <Box
        className="main_box"
        display="flex"
        justifyContent="space-around"
        gap="20px"
      >
        <Box className="left_box">
          <Box
            display="flex"
            flexDirection="column"
            sx={{ paddingTop: '20px' }}
          >
            {clientSecret && (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <StripeForm
                  setPaymentFailed={setPaymentFailed}
                  setErrorMessage={setErrorMessage}
                />
              </Elements>
            )}
          </Box>
        </Box>
        <Box sx={{ paddingTop: '20px' }} className="right_box">
          <Box minWidth="90%" marginX="auto">
            <Typography color="black.light" fontWeight="600" marginBottom="9px">
              Package
            </Typography>
            <Box
              justifyContent="space-between"
              paddingX="15px"
              width="100%"
              height="48px"
              border="2px solid"
              borderColor="borderLight.main"
              display="flex"
              flexDirection="row"
              alignItems="center"
              borderRadius="8px"
              color="primary"
              sx={{ backgroundColor: 'textWhite.main' }}
            >
              <Typography
                color="black.light"
                fontWeight="600"
                fontSize="14px"
                borderLeft="4px solid"
                borderColor="primary.main"
                paddingX="6px"
              >
                {items?.map((item) => item?.packageName).join(', ')}
              </Typography>
              {!isBirthdayPackSelected && (
                <Typography
                  color="black.light"
                  fontWeight="600"
                  fontSize="14px"
                >
                  {totalMinutes} Minutes
                </Typography>
              )}
            </Box>
            <Typography
              className="underline_text"
              fontSize="14px"
              onClick={() => navigate('/user/dashboard/packages')}
            >
              change package
            </Typography>
          </Box>
          <div className="order_info">
            <OrderCard paymentMethod />
          </div>
        </Box>
      </Box>
    </Layout>
  );
};

export default Payment;
