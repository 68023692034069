import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import CustomCalender from './CustomCalender';
import ActivitySelector from './ActivitySelector';
import ProfileSelector from './ProfileSelector';
import TimeSlotSelector from './TimeSlotSelector';
import {
  useLazyGetActivitiesQuery,
  useLazyGetProfileSessionQuery,
  useCreateBookingMutation,
  useCheckPackageByProfileMinuteMutation,
} from '../../../services/apis/BookingService';
import NoPackage from '../NoServiceComponents/NoPackage';
import {
  setPackageAvailable,
  IspackageAvailable,
  setCurrentBookingTab,
  setSelectedBirthdayPartyUser,
} from '../../../store/slices/BookingSlice';
import Shimmer from '../../../common/components/Skeletons/Shimmer';
import ProfileSkeleton from '../../../common/components/Skeletons/ProfileSkeleton';
import '../styles/style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleItemInCart } from '../../../store/slices/CartSlice';
import { BIRTHDAY_PARTY_PACKAGE } from '../../../common/utils/Constants';
import { useGetAccountDetails } from '../../../common/hooks/useGetAccountDetails';

const BookNow = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { account } = useGetAccountDetails();
  const ispackageAvailable = useSelector(IspackageAvailable);
  const [selectedActivity, setSelectedActivity] = useState('');
  const [reservationData, setReservationData] = useState({
    accountId: account?.accountId,
    profileId: null,
    sessionId: null,
    activityId: null,
    slotsBooked: null,
    sessionTime: '',
    date: '',
  });
  const packages = useSelector((state) => state.cart?.items);
  function hasBirthdayPackage(packages) {
    return packages.some((p) => p.packageName === 'Birthday Party Pack');
  }
  const [profileSession, setProfileSession] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [trigger, { data: activities, isFetching }] =
    useLazyGetActivitiesQuery();
  const [
    triggerProfile,
    {
      data: profileSessionData,
      error: profileError,
      isFetching: isProfileLoading,
    },
  ] = useLazyGetProfileSessionQuery();
  const [checkProfileMinute] = useCheckPackageByProfileMinuteMutation();

  const [createBooking] = useCreateBookingMutation();

  const onDateSelection = (date) => {
    setReservationData((prevData) => ({
      ...prevData,
      date: date.format('YYYY-MM-DD'),
    }));
    setProfileSession(null);
    trigger(date.format('DD/MM/YYYY'));
  };

  const onActivitySelect = (activity) => {
    setReservationData((prevData) => ({
      ...prevData,
      sessionId: activity.id,
      activityId: activity.activity.id,
    }));

    setSelectedActivity(activity.activity.activityName);
    triggerProfile({
      accountId: account?.accountId,
      dayTypeId: activity.dayType.id,
      activityId: activity.activity.id,
      bookingDate: reservationData.date,
    });
  };

  const onProfileSelect = (profile) => {
    setReservationData((prevData) => ({
      ...prevData,
      profileId: Number(profile.id),
    }));
    setSelectedProfile(profile);
  };
  const onSlotSelect = (slot) => {
    setReservationData((prevData) => ({
      ...prevData,
      slotsBooked: 1,
      sessionTime: slot.sessionTime,
      sessionId: slot.sessionId,
    }));
  };

  const handleReservationClick = async () => {
    const profile = await checkProfileMinute(reservationData.profileId);
    if (profile.data.minutes < 60 && selectedActivity !== 'Birthday Party') {
      dispatch(setPackageAvailable(false));
    } else if (selectedActivity === 'Birthday Party') {
      if (!hasBirthdayPackage(packages)) {
        dispatch(toggleItemInCart(BIRTHDAY_PARTY_PACKAGE));
      }
      dispatch(setSelectedBirthdayPartyUser(selectedProfile));
      navigate('/user/dashboard/order');
    } else {
      const payload = reservationData;
      payload.accountId = account.accountId;
      const response = await createBooking(payload);
      if (response.data) {
        dispatch(setCurrentBookingTab(1));
      }
    }
  };
  const isDataComplete = () => {
    return Object.values(reservationData).every(
      (value) => value !== null && value !== ''
    );
  };

  useEffect(() => {
    if (profileSessionData && !profileError?.status) {
      setProfileSession(profileSessionData);
    } else if (profileError) {
      setProfileSession({ sessions: [], profiles: [] });
    }
  }, [profileSessionData, profileError]);

  useEffect(() => {
    return () => {
      dispatch(setPackageAvailable(true));
    };
  }, [dispatch]);

  return (
    <>
      {ispackageAvailable ? (
        <Box display={'flex'} flexDirection={'column'} gap={4}>
          <Box
            sx={{
              display: 'flex',

              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: { xs: 'center', sm: 'flex-start' },
              alignItems: { xs: 'center', sm: 'flex-start' },
              gap: { xs: '5px', sm: '52px' },
            }}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-start'}
            >
              <Typography
                textAlign={'left'}
                fontSize={'1.2rem !important'}
                my={'16px !important'}
                fontWeight={'700 !important'}
                color={'#4B3E3D !important'}
              >
                Select a Date
              </Typography>
              <CustomCalender
                onlyEnableSunday={false}
                onDateSelection={(date) => onDateSelection(date)}
              />

              {isFetching && <Shimmer />}
              {activities && !isFetching && (
                <ActivitySelector
                  activities={activities}
                  onSelectActivity={onActivitySelect}
                />
              )}
            </Box>

            <Box>
              {profileSession && !isProfileLoading && (
                <ProfileSelector
                  profileData={profileSession?.profiles}
                  onProfileSelect={onProfileSelect}
                />
              )}

              {profileSession && !isProfileLoading && (
                <TimeSlotSelector
                  selectedActivity={selectedActivity}
                  sessionData={profileSession?.sessions}
                  onSlotSelect={onSlotSelect}
                  selectedDate={reservationData.date}
                />
              )}

              {isProfileLoading && (
                <Box display={'flex'} flexDirection={'column'} gap={4}>
                  <ProfileSkeleton />
                  <Shimmer />
                  <ProfileSkeleton />
                  <Shimmer />
                  <ProfileSkeleton />
                  <Shimmer />
                </Box>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              marginLeft: {
                lg: '305px',
              },
              justifyContent: {
                lg: 'stretch',
                xs: 'center',
              },
            }}
          >
            <Button
              variant="contained"
              sx={{
                textTransform: 'capitalize',
                background: 'primary.main',
                color: 'textWhite.main',
                borderRadius: '32px',
                width: '340px',
                height: '48px',
                fontSize: '1.2rem !important',
                textDecoration: 'none',
              }}
              // fontSize={'1.5rem'}
              disabled={!isDataComplete()}
              onClick={handleReservationClick}
            >
              Reserve
            </Button>
          </Box>
        </Box>
      ) : (
        <Box display={'flex'} justifyContent={'center'} alignItems="center">
          <NoPackage />
        </Box>
      )}
    </>
  );
};

export default BookNow;
