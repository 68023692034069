import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import PlusCircle from '../../assets/Images/plus-circle.svg';

import './Upload.scss';

const Upload = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box className="dashed_box">
        <Box className="btn_div" mt={2}>
          <img src={PlusCircle} alt="add avatar" />
        </Box>
        <Box mb={2}>
          <Button
            variant="outlined"
            color="primary"
            className="add_btn"
            sx={{
              fontWeight: '600',
              textTransform: 'capitalize',
              fontSize: '15px',
              fontFamily: 'SF Pro Display',
            }}
            onClick={() => {
              navigate('addChild');
            }}
          >
            Add Children/Adult
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Upload;
