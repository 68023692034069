import React, { useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';

import { tabStyles } from './styles/styles';
import BookNow from './BookNow/BookNow';
import BookingList from './MyBookings/BookingList';
import {
  currentBookingTab,
  setCurrentBookingTab,
  resetBookingSlice,
} from '../../store/slices/BookingSlice';

import { useDispatch, useSelector } from 'react-redux';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function TabsControl() {
  const value = useSelector(currentBookingTab);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    dispatch(setCurrentBookingTab(newValue));
  };

  useEffect(() => {
    return () => {
      dispatch(resetBookingSlice());
    };
  }, [dispatch]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          marginLeft: {
            lg: '368px',
          },
          justifyContent: {
            lg: 'stretch',
            xs: 'center',
          },
        }}
      >
        <Tabs
          sx={{
            width: '233px',
            height: '50px',
            minHeight: '50px',

            display: 'flex',
            justifyContent: 'space-between',
            p: '3px',
            borderRadius: '60px',
            border: '1px solid #D6D6D6',
            fontWeight: '590',
            '& .MuiTabs-indicator': {
              display: 'none',
            },
            color: '#676767',
          }}
          value={value}
          onChange={handleChange}
          textColor="inherit"
        >
          <Tab sx={tabStyles} label="Book Now" />

          <Tab sx={tabStyles} label="My Bookings" />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0} sx={{ padding: 0 }}>
        <BookNow />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <BookingList />
      </TabPanel>
    </Box>
  );
}
