import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box mt={'auto'}>
      <Box
        mt={3}
        display={'flex'}
        justifyContent="center"
        fontFamily="SF Pro Display"
        borderTop={'1px solid #b1b1b1'}
        padding="0.5rem"
        color="#4B3E3D"
      >
        <Typography fontSize={'13px'} fontWeight="400">
          © Copyright KidzCubicle | All Rights Reserved
          <span style={{ fontWeight: '500' }}> • Privacy Policy</span>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
