import placekit from '@placekit/client-js';

export const calculateAge = (dob) => {
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

export const transformApiResults = (apiResults) => {
  return apiResults.map((result) => {
    let label = '';
    let value = '';
    if (result.street && result.street.name) {
      label = `${result.street?.number && `${result.street?.number},`} ${
        result.street.name
      }`;
      value = label;
    }
    if (result.zipcode && result.zipcode.length > 0) {
      label += ` ${result.zipcode[0]}`;
    }
    label += ` ${result.city}, ${result.country}`;
    return { value: value, label, zipcode: result.zipcode[0] };
  });
};

export const findAddress = (debouncedAddress, setAddresses) => {
  if (!debouncedAddress) return;
  placekit(process.env.REACT_APP_PLACEKIT_API_KEY)
    .search(debouncedAddress)
    .then(({ results }) => {
      const addresses = transformApiResults(results);
      setAddresses(addresses);
    })
    .catch((error) => {
      console.error('Error fetching addresses:', error);
    });
};

export const checkFieldInput = (
  event,
  setValue,
  regex = /^[A-Za-z0-9 ]*$/,
  maxLength
) => {
  const value = event.target.value;
  const name = event.target.name;

  if (!maxLength || value.length <= maxLength) {
    if (!value || regex.test(value)) {
      setValue(
        name,
        value ? value.charAt(0).toUpperCase() + value.slice(1) : ''
      );
    }
  }
};

export const formatTimer = (time) => {
  const minutes = Math.floor(time / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (time % 60).toString().padStart(2, '0');
  return `${minutes}:${seconds}`;
};
