import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CartPopupItem } from '../../common/components/CartPopupItem';
import { Stack, Button, Popover, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { setShowCartPopup } from '../../store/slices/BookingSlice';

export const CartPopup = ({ isOpen }) => {
  const { items } = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCartVisitClick = () => {
    navigate('/user/dashboard/order');
    dispatch(setShowCartPopup(false));
  };

  return (
    <Popover
      open={isOpen}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      disableScrollLock
      onClose={() => dispatch(setShowCartPopup(false))}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{ marginTop: '38px', padding: '15px' }}
    >
      <Typography variant="h5" m={1}>
        Cart Items ({items.length})
      </Typography>
      <Stack
        sx={{
          maxHeight: '220px',
          width: '400px',
          p: 2,

          overflowY: 'auto',
        }}
        gap={'15px'}
      >
        {items.length > 0 ? (
          <>
            {items.map((cartItem, index) => (
              <CartPopupItem
                key={cartItem.id}
                index={index}
                packageItem={cartItem}
              />
            ))}
          </>
        ) : (
          <Typography textAlign={'center'}>No items in the cart</Typography>
        )}
      </Stack>
      <Box
        sx={{
          m: '20px',
        }}
      >
        <Button
          onClick={handleCartVisitClick}
          variant="contained"
          color="inherit"
          sx={{
            mt: 2,
            textTransform: 'capitalize',
            backgroundColor: 'green',
            color: 'white',
            ':hover': {
              backgroundColor: 'green',
            },
          }}
        >
          Visit Cart
        </Button>
      </Box>
    </Popover>
  );
};
