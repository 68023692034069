import api from '../api';

export const packagesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllPackages: build.query({
      query: () => ({
        url: 'packages',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetAllPackagesQuery } = packagesApi;

export const { endpoints } = packagesApi;
