import React, { useState, useEffect } from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { ArrowBack } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentOtpStep,
  getNewNumber,
} from '../../../store/slices/SettingSlice';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { otp_lock } from '../../../shared/images';
import {
  confirmUserAttribute,
  sendUserAttributeVerificationCode,
  updateUserAttributes,
} from 'aws-amplify/auth';
import { formatTimer } from '../../../utils';
import { useGetAccountDetails } from '../../../common/hooks/useGetAccountDetails';
import { validateNumber } from '../../../common/utils/Validation';
const VerifyOtp = () => {
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(120);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const dispatch = useDispatch();
  const newNumber = useSelector(getNewNumber);
  const { refetchAccount } = useGetAccountDetails();

  const handleClickVerify = async () => {
    setIsLoading(true);

    try {
      await confirmUserAttribute({
        confirmationCode: otp,
        userAttributeKey: 'phone_number',
      });
      dispatch(setCurrentOtpStep(4));
      refetchAccount();
      setTimeout(() => {
        dispatch(setCurrentOtpStep(0));
      }, 1000);
    } catch (error) {
      setShowErrorMessage(true);
      console.error('Error verifying phone number:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendClick = async () => {
    try {
      setTimer(120);

      await updateUserAttributes({
        userAttributes: {
          phone_number: `+${newNumber.phone}${newNumber.phoneNumber}`,
        },
      });

      await sendUserAttributeVerificationCode({
        userAttributeKey: 'phone_number',
      });
    } catch (error) {
      console.error('Error in handleResendClick:', error);
      // Handle the error as needed for your application
    }
  };

  const handleBackClick = () => {
    dispatch(setCurrentOtpStep(2));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={2}
      justifyContent={'center'}
      alignItems={'center'}
      padding={1}
    >
      <img src={otp_lock} alt="otp lock" />
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Typography fontSize={'1.8rem'} color={'#4B3E3D'}>
          Enter One Time Password
        </Typography>

        <Typography>We have sent an OTP to your phone</Typography>

        <Typography color={'lightBlue.main'}>
          {timer > 0 ? formatTimer(timer) : '0:00'}
        </Typography>
      </Box>
      <MuiOtpInput
        value={otp}
        onChange={setOtp}
        onComplete={setOtp}
        length={6}
        autoFocus
        validateChar={validateNumber}
        TextFieldsProps={{}}
      />
      {showErrorMessage && timer > 0 && (
        <Typography color={'#FF0000D9'} fontWeight={'500'}>
          Please enter valid OTP
        </Typography>
      )}
      {timer < 0 && (
        <Typography color={'#FF0000D9'} fontWeight={'500'}>
          Timed out. Please click Resend OTP to get new code
        </Typography>
      )}
      <Button
        variant="primary"
        sx={'100%'}
        onClick={handleClickVerify}
        disabled={otp.length < 6 || timer < 0}
      >
        Verify
        {isLoading && (
          <CircularProgress sx={{ marginLeft: 2 }} size={22} color="inherit" />
        )}
      </Button>
      <Button
        variant="outlined"
        sx={{ width: '100%', borderRadius: '32px', height: '42px' }}
        onClick={handleResendClick}
      >
        Resend OTP
      </Button>
      <Box
        className="cursor-pointer"
        display={'flex'}
        gap={1}
        onClick={handleBackClick}
      >
        <ArrowBack />
        <Typography>Back</Typography>
      </Box>
    </Box>
  );
};

export default VerifyOtp;
