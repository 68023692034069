import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';
import { qr_code } from '../../../shared/images';
import BarcodePopup from './BarCodePopup';
import DeleteIcon from '../../../common/components/icons/DeleteIcon';
import { DeleteModal } from '../../../common/components/DeleteModal/DeleteModal';
import { useDeleteBookingMutation } from '../../../services/apis/BookingService';
import moment from 'moment';
export const BookingsCard = ({
  name,
  date,
  time,
  status,
  duration,
  barcode,
  bookingId,
  activityName,
  refetchBookingList,
}) => {
  const [deleteBooking] = useDeleteBookingMutation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const handleClickOpenQr = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleCloseDelete = () => {
    setIsDeletePopup(false);
  };

  const handleCloseQr = () => {
    setIsPopupOpen(false);
  };
  const handleDeleteIconClick = (e) => {
    e.stopPropagation();
    setIsDeletePopup(!isDeletePopup);
  };
  const handleDeleteSuccess = async () => {
    await deleteBooking(bookingId);
    refetchBookingList();
  };

  const qrCodeData = { barcode };
  return (
    <Box
      width={'100%'}
      m={2}
      sx={{ cursor: 'pointer' }}
      onClick={() => handleClickOpenQr()}
    >
      {isPopupOpen && (
        <BarcodePopup
          handleCloseQr={handleCloseQr}
          open={isPopupOpen}
          qrCodeData={qrCodeData}
        />
      )}
      <Typography>Your Booking for {activityName ?? ''}</Typography>

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display={'flex'} gap={1} alignItems={'center'}>
          <Typography
            textTransform={'capitalize'}
            fontWeight={'700'}
            variant="h5"
            color="#4B3E3D"
          >
            {name}
          </Typography>
          <img height={'24px'} width={'24px'} src={qr_code} alt="QR Code" />
        </Box>
        <Box
          // mb={-1}
          onClick={(e) => handleDeleteIconClick(e)}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={'column'}
        >
          <DeleteIcon />
          {isDeletePopup && (
            <DeleteModal
              onClose={handleCloseDelete}
              onDelete={handleDeleteSuccess}
              open={isDeletePopup}
            />
          )}
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        textAlign={'left'}
        mt={1}
      >
        <Box>
          <Typography mt={'2px'} variant="body2" color="#4B3E3D">
            Date
          </Typography>
          <Typography color="#4B3E3D" variant="body2" fontWeight={'600'}>
            {moment(date).format('DD/MM/YYYY')}
          </Typography>
          <Typography mt={'2px'} variant="body2" color="#4B3E3D">
            Status
          </Typography>
          <Typography color="#4B3E3D" variant="body2" fontWeight={'600'}>
            {status}
          </Typography>
        </Box>

        <Box textAlign={'initial'}>
          <Typography mt={'2px'} variant="body2" color="#4B3E3D">
            Time
          </Typography>
          <Typography color="#4B3E3D" variant="body2" fontWeight={'600'}>
            {time}
          </Typography>
          <Typography mt={'2px'} variant="body2" color="#4B3E3D">
            Duration
          </Typography>
          <Typography color="#4B3E3D" variant="body2" fontWeight={'600'}>
            {duration}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
