import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import * as Yup from 'yup';

import Input from '../../common/components/Input';

import '../../styles/common/Common.scss';

import Title from '../../common/components/Title';
import FormControlLabel from '@mui/material/FormControlLabel';

import Alert from '@mui/material/Alert';

import './SignUp.scss';
import SubTitle from '../../common/components/SubTitle';
import { useFormik } from 'formik';
import Colors from '../../theme/KidzCubicleTheme/Colors';
import { validateRequired } from '../../common/utils/Validation';
import { signUp } from 'aws-amplify/auth';

import SocialSignInLoginBtns from '../SocialSignInLoginBtns';
import { Radio } from '@mui/material';

import '../VerifyOtp/VerifyOtp.scss';
import '../../styles/common/Common.scss';
import { VerifySignupOtp } from './VerifySignupOtp';

const emailValidationRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const signUpSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-Za-z\s]+$/, 'First Name must only contain letters')
    .required('First Name is required'),

  lastName: Yup.string()
    .matches(/^[A-Za-z\s]+$/, 'Last Name must only contain letters')
    .required('Last Name is required'),
  email: Yup.string()
    .email('Invalid email')
    .matches(emailValidationRegex, 'Invalid email')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-z]/, 'Password must contain at least one lowercase char')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase char')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      'Password must contain at least one special char'
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  confirmTerms: Yup.boolean()
    .required('Required')
    .oneOf(
      [true],
      'Please accept the KidzCubicle Terms and Condition before continuing'
    ),
});

const SignUp = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);

  const [errorMessage, setErrorMessage] = useState('');

  const getErrorMessage = (error) => {
    setErrorMessage(error);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      confirmTerms: false,
    },
    validationSchema: signUpSchema,
    onSubmit: (values) => {
      const { firstName, lastName, email, password } = values;
      setIsLoading(true);
      setErrorMessage('');
      signUp({
        username: email,
        password: password,
        options: {
          userAttributes: {
            name: `${firstName} ${lastName}`,
          },
        },
      })
        .then((result) => {
          if (result?.nextStep?.signUpStep === 'CONFIRM_SIGN_UP') {
            setStep(1);
          }
        })
        .catch((error) => {
          setErrorMessage(error.message || JSON.stringify(error));
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  if (step === 1) {
    return (
      <>
        <VerifySignupOtp formik={formik} setStep={setStep} />
      </>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        className="SignUp--form-container"
        rowGap={2}
        justifyContent={'space-between'}
      >
        <Grid className="SignUp__title" item xs={12} textAlign={'center'}>
          <Title name="Join Our Community!" />
          <Box mt={2}>
            <SubTitle name="We make sure that your child's journey with us is safe, enjoyable, and filled with learning adventures." />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <SocialSignInLoginBtns OnError={getErrorMessage} isSignUp />
          <Divider className="SignUp__Divider">or</Divider>
        </Grid>
        <Grid item xs={12} md={5.9} alignSelf={'flex-start'}>
          <Input
            label="First Name"
            type="text"
            name="firstName"
            placeholder={'Arthur'}
            isRequired
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.firstName}
            error={formik.errors.firstName}
            noHeight
          />
        </Grid>
        <Grid item xs={12} md={5.9} alignSelf={'flex-start'}>
          <Input
            label="Last Name"
            type="text"
            name="lastName"
            placeholder={'Duncan'}
            isRequired
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.lastName}
            error={formik.errors.lastName}
            noHeight
          />
        </Grid>
        <Grid item xs={12} alignSelf={'flex-start'}>
          <Input
            label="Email"
            type="text"
            name="email"
            placeholder={'xyz@gmail.com'}
            isRequired
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.email}
            error={formik.errors.email}
            noHeight
          />
        </Grid>
        <Grid item xs={12} md={5.9} alignSelf={'flex-start'}>
          <Input
            label="Password"
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder={'************'}
            isRequired
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.password}
            error={formik.errors.password}
            className="pr-48"
            noHeight
            endAdornment={
              <InputAdornment position="end" sx={{ ml: '6px' }}>
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12} md={5.9} alignSelf={'flex-start'}>
          <Input
            label="Confirm Password"
            type={showConfirmPassword ? 'text' : 'password'}
            name="confirmPassword"
            placeholder={'************'}
            isRequired
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.confirmPassword}
            error={formik.errors.confirmPassword}
            className="pr-48"
            noHeight
            endAdornment={
              <InputAdornment position="end" sx={{ ml: '6px' }}>
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
        <Grid xs={12}>
          <FormControlLabel
            name="confirmTerms"
            sx={{ alignItems: 'flex-start', margin: '0px' }}
            control={
              <Radio
                onChange={(e) =>
                  formik.setFieldValue('confirmTerms', e.target.checked)
                }
                name="confirmTerms"
                onBlur={formik.handleBlur}
                checked={formik.values.confirmTerms}
                sx={{ padding: 0, paddingRight: '8px' }}
                size="small"
              />
            }
            label={
              <Typography style={{ fontSize: '0.9rem' }}>
                I Agree to KidzCubicle{' '}
              </Typography>
            }
          />
          <a
            href="https://kidzcubicle.com/tnc"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <span style={{ color: Colors.PRIMARY }}>
              &nbsp;Terms and Conditions
            </span>
          </a>
          &nbsp;and
          <a
            href="https://kidzcubicle.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <span style={{ color: Colors.PRIMARY }}>
              &nbsp; Privacy Policy.
            </span>
          </a>
          {formik.errors.confirmTerms && (
            <Typography
              sx={{
                color: 'richRed.main',
                fontWeight: '600',
                fontSize: '12px',
                marginTop: '1px',
                textTransform: 'capitalize',
              }}
            >
              {formik.errors.confirmTerms}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          {validateRequired(errorMessage) && (
            <Grid item xs={12} mb={2}>
              <Alert severity="error">
                Sign up failed with error 'You already have an account with this
                email id'
              </Alert>
            </Grid>
          )}
          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            disabled={
              isLoading ||
              !formik.isValid ||
              !Object.keys(formik.touched).length
            }
            className="Common__Login-btn"
            sx={{ mt: 1, color: Colors.WHITE_COLOR }}
          >
            {!isLoading ? 'Sign up' : <CircularProgress size={24} />}
          </Button>
        </Grid>
        <Grid xs={12}>
          <Box textAlign={'center'}>
            <small style={{ color: Colors.INPUT_TEXT_COLOR }}>
              Already have an account?{' '}
              <b
                style={{ color: Colors.PRIMARY, cursor: 'pointer' }}
                onClick={() => navigate('/login')}
              >
                Sign in
              </b>
            </small>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default SignUp;
