import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '200px',
  width: '100%',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  padding: '30px 10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const imgStyle = {
  maxWidth: '76px',
  width: '100%',
  height: '76px',
};

export default function CustomModal({ open, setOpen, error = false }) {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: { timeout: 500 },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          {error ? (
            <ErrorOutlineIcon color="error" />
          ) : (
            <img src="/add-check.png" style={imgStyle} alt="add" />
          )}
          {error ? (
            <Typography
              id="transition-modal-title"
              variant="body"
              component="h4"
              color="error"
            >
              something went wrong
            </Typography>
          ) : (
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Added
            </Typography>
          )}
        </Box>
      </Fade>
    </Modal>
  );
}
