import { Box, Skeleton } from '@mui/material';
import React from 'react';

const Shimmer = () => {
  return (
    <Box width={'350px'}>
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
    </Box>
  );
};

export default Shimmer;
