import { Box, Switch, Typography } from '@mui/material';
import React from 'react';
import { switchStyle } from './style';
import { useUpdateMarketingPreferenceMutation } from '../../services/apis/SettingService';
import { useGetAccountDetails } from '../../common/hooks/useGetAccountDetails';
const SettingCard = ({
  CardTitle,
  onCardClick,
  isMarketing = false,
  isDisabled = false,
}) => {
  const [updateMarketingPreference] = useUpdateMarketingPreferenceMutation();
  const { account, refetchAccount } = useGetAccountDetails();

  const handleCardClick = () => {
    if (onCardClick) {
      onCardClick();
    }
  };

  const handleChange = (event) => {
    const preference = event.target.checked ? 'Yes' : 'No';

    updateMarketingPreference({
      accountId: Number(account?.accountId),
      preferences: {
        marketingPreference: preference,
        notificationPreference: preference,
      },
    }).then(() => {
      refetchAccount();
    });
  };
  return (
    <div>
      <Box
        boxShadow={'0px 1px 4px 0px #00000029'}
        height={'60px'}
        padding={'5px'}
        borderRadius={'5px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        maxWidth={'700px'}
        backgroundColor={isDisabled ? '#e7e7e7' : ''}
        onClick={handleCardClick}
        className={isDisabled ? '' : 'cursor-pointer'}
      >
        <Typography color={'#000000B2'} fontSize={'1rem'} marginLeft={'19px'}>
          {CardTitle}
        </Typography>
        {isMarketing && (
          <Switch
            checked={account?.marketingPreference === 'Yes'}
            onChange={handleChange}
            size="medium"
            sx={switchStyle}
          />
        )}
      </Box>
    </div>
  );
};

export default SettingCard;
