import { Box } from '@mui/system';
import React from 'react';
import Footer from '../../common/components/Footer/Footer';
import Navbar from '../../common/components/Navbar/Navbar';

export default function Layout({ children }) {
  return (
    <Box display={'flex'} minHeight="100vh" flexDirection={'column'}>
      <Navbar />
      <Box>{children}</Box>
      <Footer />
    </Box>
  );
}
