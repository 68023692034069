import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  promoCodeDetails: null,
  paymentIntentId: null,
  clientSecret: null,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    toggleItemInCart(state, action) {
      const { id, packageName } = action.payload;
      const existingItemIndex = state.items.findIndex((item) => item.id === id);
      const isBirthdayParty = packageName.includes('Birthday Party Pack');
      const isBirthdayPackSelected = state.items.find(
        (item) => item?.packageName === 'Birthday Party Pack'
      );

      if (isBirthdayPackSelected && !isBirthdayParty) return;

      if (existingItemIndex !== -1) {
        state.items = state.items.filter((item) => item.id !== id);
      } else {
        if (isBirthdayParty) {
          state.items = [
            {
              ...action.payload,
              quantity: 1,
            },
          ];
        } else {
          state.items.push({
            ...action.payload,
            quantity: 1,
          });
        }
      }
      state.promoCodeDetails = null;
      state.paymentIntentId = null;
    },
    removeItemFromCart(state, action) {
      const id = action.payload;
      state.items = state.items.filter((item) => item.id !== id);
      state.promoCodeDetails = null;
      state.paymentIntentId = null;
    },
    increaseItemQuantity(state, action) {
      const id = action.payload;
      const existingItem = state.items.find((item) => item.id === id);
      if (existingItem) {
        existingItem.quantity += 1;
      }
    },
    decreaseItemQuantity(state, action) {
      const { id } = action.payload;
      const existingItem = state.items.find((item) => item.id === id);
      if (existingItem && existingItem.quantity > 1) {
        existingItem.quantity -= 1;
      }
    },
    addPromoCodeDetails(state, action) {
      state.promoCodeDetails = action.payload;
    },
    addPaymentIntentId(state, action) {
      state.paymentIntentId = action.payload;
    },
    addClientSecret(state, action) {
      state.clientSecret = action.payload;
    },
    clearCart(state) {
      state.items = [];
      state.promoCodeDetails = null;
      state.paymentIntentId = null;
      state.clientSecret = null;
    },
  },
});

export const {
  toggleItemInCart,
  removeItemFromCart,
  increaseItemQuantity,
  decreaseItemQuantity,
  addPromoCodeDetails,
  clearCart,
  addPaymentIntentId,
  addClientSecret,
} = cartSlice.actions;

export default cartSlice.reducer;
