import React, { useState } from 'react';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import Title from '../../common/components/Title';
import ResetPasswordLogo from '../../assets/Images/forgotPasswordOtp.svg';
import './ResetPassword.scss';
import '../../styles/common/Common.scss';
import Colors from '../../theme/KidzCubicleTheme/Colors';
import {
  PASSWORD_RESET_INITIAL_VALUES,
  passwordResetSchema,
} from '../../common/utils/AuthUtils';
import { Formik, Field, Form } from 'formik';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import { validateRequired } from '../../common/utils/Validation';
import Alert from '@mui/material/Alert';

import Input from '../../common/components/Input';
import { confirmResetPassword } from 'aws-amplify/auth';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [params] = useSearchParams();
  const email = params.get('sub');
  const otp = params.get('code');

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmNewPassword = () =>
    setShowConfirmNewPassword((show) => !show);

  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownConfirmNewPassword = (event) => {
    event.preventDefault();
  };

  const handleCompleteResetPassword = async (values) => {
    setIsLoading(true);
    try {
      await confirmResetPassword({
        confirmationCode: otp,
        newPassword: values.newPassword,
        username: email,
      });
      setStep(1);
    } catch (error) {
      setErrorMessage(error.message || JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  };

  if (step === 1) {
    return (
      <Grid container className="ResetPassword--form-container">
        <Grid className="ResetPassword__title" item xs={12}>
          <Box textAlign={'center'}>
            <img src={ResetPasswordLogo} alt="forgot-password" />
            <Box my={1}>
              <Title name="Password reset successfully" />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          marginTop={2}
        >
          <NavLink to={'/login'} replace>
            <Button
              className="Common__Login-btn"
              color="primary"
              variant="contained"
              sx={{
                color: Colors.WHITE_COLOR,
                paddingX: '36px',
              }}
            >
              Login
            </Button>
          </NavLink>
        </Grid>
      </Grid>
    );
  }

  return (
    <Formik
      initialValues={PASSWORD_RESET_INITIAL_VALUES}
      validationSchema={passwordResetSchema}
      onSubmit={handleCompleteResetPassword}
    >
      <Form>
        <Grid container className="ResetPassword--form-container">
          <Grid className="ResetPassword__title" item xs={12}>
            <Box textAlign={'center'}>
              <img src={ResetPasswordLogo} alt="forgot-password" />
              <Box my={1}>
                <Title name="Set new password" />
              </Box>
            </Box>
          </Grid>
          <Grid className="ResetPassword__form" item xs={12}>
            <Box mt={2}>
              <Field name="newPassword" type="password">
                {({ field, form }) => (
                  <Input
                    {...field}
                    type={showNewPassword ? 'text' : 'password'}
                    className="pr-48"
                    id="outlined-adornment-password"
                    placeholder={'********'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownNewPassword}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New Password"
                    touched={form.touched.newPassword}
                    error={form.errors.newPassword}
                    noHeight
                  />
                )}
              </Field>
            </Box>
            <Box mt={2}>
              <Field name="confirmNewPassword" type="password">
                {({ field, form }) => (
                  <Input
                    {...field}
                    className="pr-48"
                    type={showConfirmNewPassword ? 'text' : 'password'}
                    id="outlined-adornment-password"
                    placeholder={'********'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmNewPassword}
                          onMouseDown={handleMouseDownConfirmNewPassword}
                          edge="end"
                        >
                          {showConfirmNewPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    touched={form.touched.confirmNewPassword}
                    error={form.errors.confirmNewPassword}
                    label="Confirm New Password"
                    noHeight
                  />
                )}
              </Field>
            </Box>
          </Grid>
          {validateRequired(errorMessage) && (
            <Grid item xs={12} mt={2}>
              <Alert severity="error">{errorMessage}</Alert>
            </Grid>
          )}
          <Grid className="ResetPassword__buttons" item xs={12}>
            <Button
              className="Common__Login-btn"
              fullWidth
              color="primary"
              variant="contained"
              disabled={isLoading}
              sx={{ color: Colors.WHITE_COLOR }}
              type="submit"
            >
              {!isLoading ? (
                'Confirm'
              ) : (
                <CircularProgress size={24} sx={{ color: 'white' }} />
              )}
            </Button>
            <Typography
              className="ResetPassword__back-to-btn"
              onClick={() => {
                navigate('/login', { replace: true });
              }}
            >
              <ArrowBackIcon fontSize="small" mx={2} />
              <span>Back to Log in</span>
            </Typography>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export default ResetPassword;
