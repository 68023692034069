const BUTTON = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
    },
  },

  variants: [
    {
      props: { variant: 'primary' },
      style: {
        background: '#F39200',
        height: '42px',
        color: 'white',
        borderRadius: '32px',
        width: '100%',
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: '#F39200',
          color: 'white',
        },
        '&:disabled': {
          backgroundColor: '#ECECEC',
          color: '#B1B1B1',
        },
      },
    },
  ],
};
export default BUTTON;
