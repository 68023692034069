import { Box, Typography } from '@mui/material';
import { useRef, useState, useEffect } from 'react';

const Dropdown = ({
  name = null,
  clear = false,
  options = [],
  setFieldValue,
  error,
  touched,
  disabled,
}) => {
  const [selectedValue, setSelectedValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleOptionSelect = (option) => {
    setSelectedValue(option.label);
    setFieldValue(option.label);

    setIsOpen(false);
  };

  const handleAddressSearch = (address) => {
    setSelectedValue(address);
    setFieldValue(address);
    setIsOpen(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <Box
        backgroundColor="textWhite.main"
        display="flex"
        justifyContent="space-between"
        cursor="pointer"
        alignItems="center"
        width="100%"
        outline="none"
        padding="0.7rem"
        border="1px solid"
        borderColor={error && touched && 'red'}
        borderRadius="8px"
        marginTop="5px"
        height="49px"
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        <Box
          name={name}
          component="input"
          outline="none"
          border="none"
          width="100%"
          fontSize="15px"
          height="20px"
          fontWeight={400}
          value={clear ? '' : selectedValue}
          placeholder="Add address"
          disabled={disabled}
          bgcolor={'#fff'}
          onChange={(e) => handleAddressSearch(e.target.value)}
        />

        <div className="dropdown-icon ">
          <img width={10} height={10} src="/arrowDown.svg" alt="dropdown" />
        </div>
      </Box>
      {isOpen && options.length > 0 && (
        <Box
          ref={dropdownRef}
          color="textWhite.main"
          display="flex"
          flexDirection="column"
          cursor="pointer"
          alignItems="center"
          width="100%"
          outline="none"
          maxHeight="200px"
          border="1px solid "
          borderColor="borderLight.main"
          marginTop="0.5rem"
          borderRadius="8px"
          sx={{ overflowY: 'scroll' }}
        >
          {options?.map((option, i) => {
            return (
              <Box
                key={option.value}
                width="100%"
                color="black.light"
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'lightBlue.main',
                    color: 'textWhite.main',
                  },
                }}
                onClick={() => handleOptionSelect(option)}
              >
                <Typography
                  fontWeight={500}
                  fontSize="16px"
                  borderBottom={i + 1 < options.length ? '1px solid ' : 'none'}
                  borderColor="borderLight.main"
                  padding="0.6rem 1.5rem"
                >
                  {option.label}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}
      <div>
        {error && touched && (
          <Typography
            sx={{
              color: 'richRed.main',
              fontWeight: '600',
              fontSize: '12px',
              marginTop: '1px',
              textTransform: 'capitalize',
            }}
          >
            Address is required
          </Typography>
        )}
      </div>
    </>
  );
};

export default Dropdown;
