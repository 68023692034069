import { Box, Typography, Button } from '@mui/material';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useEffect } from 'react';
import AssignPercentageToChildModal from './AssignPercentageToChildModal';
import { useGetAccountDetails } from '../../common/hooks/useGetAccountDetails';
import { useGetAllocationStatusQuery } from '../../services/apis/PaymentService';
const StripeForm = ({ setPaymentFailed, setErrorMessage }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { account } = useGetAccountDetails();
  const { data } = useGetAllocationStatusQuery(account?.accountId, {
    skip: !Boolean(account?.accountId) && account?.accountStatus !== 'INACTIVE',
  });
  const [open, setOpen] = useState(false);
  const [intentData, setIntentData] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { clientSecret } = useSelector((state) => state.cart);

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    if (!clientSecret || !stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements.submit();

    if (submitError) {
      setPaymentFailed(true);
      setIsLoading(false);
      return;
    }

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      clientSecret,
      redirect: 'if_required',
    });

    if (!error) {
      paymentIntent.amount /= 100;
      setIntentData(paymentIntent);
      setOpen(true);
      setIsLoading(false);
    } else {
      setPaymentFailed(true);
      setIsLoading(false);
      setErrorMessage(error?.message);
    }

    setIsLoading(false);
  };
  useEffect(() => {
    if (data && data.length > 0) {
      const unallocatedTransaction = data.find(
        (transaction) =>
          transaction.allocationStatus === 'False' &&
          transaction.paymentStatus === 'succeeded'
      );
      if (unallocatedTransaction) {
        setOpen(true);
        setIntentData(unallocatedTransaction);
      }
    }
  }, [data]);
  if (!clientSecret || !stripe || !elements) {
    return <CircularProgress />;
  }

  return (
    <>
      <AssignPercentageToChildModal
        open={open}
        setOpen={setOpen}
        intentData={intentData}
        minutes={intentData?.description}
      />
      <form className="stripe-form" onSubmit={handleSubmit}>
        <Box paddingY="10px">
          <Typography
            color="#4B3E3D"
            fontWeight="600"
            marginBottom="10px"
            fontSize="18px"
          >
            Card Information
          </Typography>
          <Box width="100%">
            <Box>{clientSecret !== null && <PaymentElement />}</Box>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fontWeight="600"
              sx={{
                borderRadius: '100px',
                color: '#ffffff',
                textTransform: 'capitalize',
                width: '174px',
                height: '48px',
                fontSize: '18px',
                margin: '12px 0px !important',
              }}
            >
              {!isLoading ? (
                'Make Payment'
              ) : (
                <CircularProgress size={22} color="textWhite" />
              )}
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default StripeForm;
