import React, { useEffect, useState } from 'react';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Box,
  Typography,
  Grid,
} from '@mui/material';
import moment from 'moment';
import NoTimeSlot from '../NoServiceComponents/NoTimeSlot';
import { AddHour } from '../../../common/utils/DateUtils';

const TimeSlotSelector = ({
  sessionData,
  onSlotSelect,
  selectedActivity,
  selectedDate,
}) => {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [sortedSessions, setSortedSessions] = useState(null);
  const now = moment();

  const today = moment().startOf('day');

  const handleChange = (event, value) => {
    setSelectedTimeSlot(event.target.value);
    onSlotSelect(value);
  };
  useEffect(() => {
    if (sessionData) {
      if (moment(selectedDate).startOf('day').isSame(today)) {
        const filteredAndSortedData = [...sessionData]
          .filter((session) => {
            const sessionTime = moment(session.sessionTime, 'HH:mm');
            return sessionTime.isAfter(now);
          })
          .sort((a, b) => {
            const timeA = moment(a.sessionTime, 'HH:mm');
            const timeB = moment(b.sessionTime, 'HH:mm');
            return timeA - timeB;
          });

        setSortedSessions(filteredAndSortedData);
      } else {
        const filteredAndSortedData = [...sessionData].sort((a, b) => {
          const timeA = moment(a.sessionTime, 'HH:mm');
          const timeB = moment(b.sessionTime, 'HH:mm');
          return timeA - timeB;
        });

        setSortedSessions(filteredAndSortedData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionData]);

  return (
    <Box>
      <Typography
        fontSize={'1.2rem !important'}
        my={'16px !important'}
        fontWeight={'700 !important'}
        color={'#4B3E3D !important'}
      >
        Choose a Time Slot
      </Typography>
      <Box>
        {sortedSessions?.length > 0 ? (
          <Box>
            <Grid
              container
              spacing={1}
              direction="column"
              gap={2}
              justifyContent={'end'}
              sx={{
                justifyContent: {
                  lg: 'end',
                  sm: 'center',
                },
              }}
            >
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="time slot"
                  name="time slot"
                  value={selectedTimeSlot}
                >
                  <Box
                    maxHeight={'240px'}
                    // maxWidth={"350px"}
                    overflow={'auto'}
                    // width={"100%"}
                    paddingRight={'2px'}
                  >
                    {sortedSessions?.map((session) => (
                      <Grid
                        xs={12}
                        key={session.id}
                        margin={1}
                        item
                        display="flex"
                        alignItems="center"
                        border={`1px solid`}
                        borderColor={
                          moment(session.sessionTime, 'H:mm').format(
                            'HH:mm'
                          ) === selectedTimeSlot
                            ? 'primary.main'
                            : '#D6D6D6'
                        }
                        borderRadius="8px"
                        padding={'14px 20px'}
                        height={'56px'}
                        sx={{
                          width: {
                            xs: '341px',
                            sm: '310px',
                            lg: '358px',
                            xl: '370px',
                          },
                        }}
                      >
                        <FormControlLabel
                          onChange={(event) =>
                            handleChange(event, {
                              sessionTime: moment(
                                session.sessionTime,
                                'H:mm'
                              ).format('HH:mm'),
                              sessionId: session.id,
                            })
                          }
                          value={moment(session.sessionTime, 'H:mm').format(
                            'HH:mm'
                          )}
                          control={<Radio />}
                          label={
                            <span>
                              {moment(session.sessionTime, 'H:mm').format(
                                'HH:mm'
                              )}{' '}
                              -
                              {selectedActivity === 'Birthday Party'
                                ? AddHour(session.sessionTime, 2)
                                : AddHour(session.sessionTime, 1)}
                            </span>
                          }
                        />
                      </Grid>
                    ))}
                  </Box>
                </RadioGroup>
              </FormControl>
            </Grid>
          </Box>
        ) : (
          <NoTimeSlot />
        )}
      </Box>
    </Box>
  );
};

export default TimeSlotSelector;
