import api from '../api';
export const settingsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTransactionList: builder.query({
      query: (accountId) => ({
        url: `/payments/account/${accountId}`,
        method: 'GET',
      }),
    }),
    updateMarketingPreference: builder.mutation({
      query: (payload) => ({
        url: `/accounts/${payload.accountId}/preferences`,
        method: 'PUT',
        body: payload.preferences,
      }),
    }),
  }),
});

export const {
  useGetTransactionListQuery,
  useUpdateMarketingPreferenceMutation,
} = settingsApi;
