import { Box, InputLabel } from '@mui/material';

const InputTitle = ({ label, isRequired }) => {
  return (
    <InputLabel
      component="label"
      sx={{
        fontWeight: 600,
        fontSize: '16px',
        color: 'black.light',
        lineHeight: '16px',
      }}
    >
      {label}
      {isRequired && (
        <Box
          component="span"
          sx={{
            color: 'primary.main',
            marginLeft: '2px',
          }}
        >
          *
        </Box>
      )}
    </InputLabel>
  );
};

export default InputTitle;
