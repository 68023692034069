import React from 'react';
import PublicLogo from '../../../logo/PublicLogo';
import { Grid, Box, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import Badge from '@mui/material/Badge';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import './Navbar.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { signOut } from 'aws-amplify/auth';
import { CartPopup } from '../../../features/Packages/CartPopup';
import {
  getShowCartPopup,
  setShowCartPopup,
} from '../../../store/slices/BookingSlice';
const Navbar = () => {
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,

      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const showCartPopup = useSelector(getShowCartPopup);

  const navigate = useNavigate();

  const onSignOut = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Grid container alignItems="center" className="Navbar--container">
      <Grid
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => navigate('/user/dashboard')}
      >
        <PublicLogo />
      </Grid>
      <Grid
        item
        xs={3}
        display="flex"
        justifyContent="flex-end"
        className="Navbar--links"
      >
        <Box p={2}>
          <IconButton aria-label="cart">
            <Tooltip title="Cart">
              <StyledBadge
                badgeContent={cart.items.length}
                color="warning"
                onClick={() => dispatch(setShowCartPopup(true))}
              >
                <ShoppingCartOutlinedIcon style={{ color: 'black' }} />
              </StyledBadge>
            </Tooltip>
          </IconButton>
          <Box mt={3}>
            <CartPopup isOpen={showCartPopup} />
          </Box>
        </Box>
        <Box p={2}>
          <Tooltip title="Logout">
            <IconButton aria-label="logout" onClick={onSignOut}>
              <LogoutOutlinedIcon style={{ color: 'black' }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Navbar;
