import React, { useState, useEffect } from 'react';
import '../../styles/common/Common.scss';

import CountryCodeList from '../../common/components/CountryCodeList';
import {
  CircularProgress,
  Button,
  Grid,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
} from '@mui/material';
import {
  updateUserAttributes,
  confirmUserAttribute,
  sendUserAttributeVerificationCode,
} from 'aws-amplify/auth';
import { formatTimer } from '../../utils';

import { validateNumber } from '../../common/utils/Validation';
import { MuiOtpInput } from 'mui-one-time-password-input';

import '../../styles/common/Common.scss';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Alert from '@mui/material/Alert';
import { validateRequired } from '../../common/utils/Validation';
import Colors from '../../theme/KidzCubicleTheme/Colors';

const RegisterPhoneDialog = ({ open, onClose }) => {
  const [code, setCode] = useState({ label: 'UK', value: '+44', flag: '🇬🇧' });
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [step, setStep] = useState(0);
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(120);
  const [verificationErrorMessage, setVerificationErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = async () => {
    setIsLoading(true);
    try {
      setError('');
      setOtp('');
      await updateUserAttributes({
        userAttributes: {
          phone_number: code.value + phone,
        },
      });
      await sendUserAttributeVerificationCode({
        userAttributeKey: 'phone_number',
      });
      setStep(1);
    } catch (error) {
      setError(error.message || JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendClick = () => {
    setTimer(120);
    setOtp('');
    onConfirm();
  };

  const handleEnterOtp = async () => {
    setIsLoading(true);
    try {
      await confirmUserAttribute({
        confirmationCode: otp,
        userAttributeKey: 'phone_number',
      });
      onClose();
    } catch (error) {
      setVerificationErrorMessage(error.message || JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (step === 1) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
    if (step === 0) {
      setTimer(120);
    }
  }, [step]);
  return (
    <Dialog open={open} fullWidth>
      {step === 0 && <DialogTitle>Enter your Phone Number</DialogTitle>}
      {step === 1 && <DialogTitle>Enter OTP</DialogTitle>}
      <DialogContent>
        {step === 0 && (
          <Grid
            container
            className="PhoneDetails--form-container"
            columnGap={1}
            rowGap={2}
            marginTop={1}
          >
            <Grid item xs={3}>
              <CountryCodeList onSelectChange={setCode} countryCode={code} />
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Phone Number"
                type="number"
                name="phone"
                variant="outlined"
                placeholder={'12345678'}
                isRequired
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                noHeight
              />
            </Grid>
            <Grid item xs={12}>
              <div className="Common__error-message">{error}</div>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled={isLoading}
                className="Common__Login-btn"
                onClick={onConfirm}
                sx={{
                  color: 'white',
                  marginTop: '8px',
                }}
              >
                {!isLoading ? 'Next' : <CircularProgress size={24} />}
              </Button>
            </Grid>
          </Grid>
        )}
        {step === 1 && (
          <>
            <Box textAlign={'center'}>
              <Typography color={'lightBlue.main'} mt={2}>
                {timer > 0 ? formatTimer(timer) : '0:00'}
              </Typography>
            </Box>
            <Grid container className="VerifyOtp--form-container">
              <Grid className="VerifyOtp__form" item xs={12}>
                <MuiOtpInput
                  value={otp}
                  onChange={setOtp}
                  onComplete={setOtp}
                  length={6}
                  autoFocus
                  validateChar={validateNumber}
                  TextFieldsProps={{}}
                />
              </Grid>
              <Grid className="VerifyOtp__buttons" item xs={12}>
                {validateRequired(verificationErrorMessage) && timer > 0 && (
                  <Grid item xs={12} mb={2}>
                    <Alert severity="error">{verificationErrorMessage}</Alert>
                  </Grid>
                )}
                {timer < 0 && (
                  <Typography
                    color={'#FF0000D9'}
                    fontWeight={'500'}
                    textAlign={'center'}
                    mb={1}
                  >
                    Timed out. Please click Resend OTP to get new code
                  </Typography>
                )}
                <Button
                  className="Common__Login-btn"
                  fullWidth
                  color="primary"
                  variant="contained"
                  sx={{ color: Colors.WHITE_COLOR }}
                  disabled={otp.length < 6 || timer < 0}
                  onClick={handleEnterOtp}
                >
                  {!isLoading ? 'Verify' : <CircularProgress size={24} />}
                </Button>
                <Box marginTop={2}>
                  <Button
                    variant="outlined"
                    sx={{
                      width: '100%',
                      borderRadius: '32px',
                      height: '42px',
                    }}
                    type="submit"
                    onClick={handleResendClick}
                  >
                    Resend OTP
                  </Button>
                </Box>
                <Typography
                  className="VerifyOtp__back-to-btn"
                  onClick={() => setStep(0)}
                >
                  <ArrowBackIcon fontSize="small" mx={2} />
                  <span>Back</span>
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RegisterPhoneDialog;
