export const skinColors = [
  { color: '#F2D0B4' },
  { color: '#F5C6A4' },
  { color: '#EFC9B6' },
  { color: '#D3996E' },
  { color: '#BC7657' },
  { color: '#9E5E47' },
  { color: '#814539' },
];

export const addressColors = [
  { color: '#4FC3F7', value: 'blue' },
  { color: '#00CED1', value: 'green' },
  { color: '#87CEEB', value: 'sky' },
  { color: '#A6C9E2', value: 'grey' },
  { color: '#FF69B4', value: 'pink' },
  { color: '#F8BBD0', value: 'lightPink' },
];

export const names = [
  { id: 1, name: 'America' },
  { id: 2, name: 'China' },
  { id: 3, name: 'Bangladesh' },
  { id: 4, name: 'India' },
  { id: 5, name: 'Pakistan' },
];
export const addresses = [
  { value: 1, label: '123 Main Street, Anytown, NY 12345' },
  { value: 2, label: '123 Main Street, Brooklyn, NY 12345' },
  { value: 3, label: '123 Main Street, Seatle, NY 12345' },
  { value: 4, label: '123 Main Street, Miami, NY 12345' },
  { value: 5, label: '123 Main Street, Atlanta, NY 12345' },
];
