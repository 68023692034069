import { Button } from '@mui/material';

const CustomButton = ({
  sx,
  variant,
  color,
  classes,
  onClick,
  children,
  disabled,
}) => {
  return (
    <Button
      disabled={disabled}
      variant={variant}
      color={color}
      className={classes}
      onClick={onClick}
      sx={sx}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
