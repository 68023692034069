const Colors = {
  PRIMARY: '#F39200',
  APP_BACKGROUND_COLOR: '#FFFFFF',
  BLACK: '#4B3E3D',
  THEME_BLACK: '#4B3E3D',
  LIGHT_GREY: '#B1B1B1',
  LIGHT_BORDER_COLOR: '#D6D6D6',
  LIGHT_GREY_BORDER_COLOR: '#B0B0B0',
  SUB_TITLE_COLOR: '#757575',
  INPUT_TEXT_COLOR: '#909090',
  GREY_COLOR_1: '#4A4A4A',
  GREY_COLOR_2: '#EEF6FB',
  LIGHT_BLUE: '#50A7DA',
  WHITE_COLOR: '#FFFFFF',
  RED_COLOR: '#FF0000',
  JET_BLACK: '#000000',
  LIGHT_GREEN: '#90EE90',
  PINK: '#FF69B4',
  WHITE_SMOKE: '#F6F8F9',
  DARK_BLACK: '#000000',
  LIGHT_BLACK: '#4C4C4C',
};

export default Colors;
