import React from 'react';
import TextField from '@mui/material/TextField';
import EditIcon from '../components/icons/EditIcon';
import { Box, InputAdornment, Typography } from '@mui/material';

const EditTextField = ({
  label,
  value,
  isEditable,
  onEditClick,
  backgroundColor,
}) => {
  const handleEditClick = () => {
    if (onEditClick) {
      onEditClick();
    }
  };
  return (
    <Box>
      <Typography textAlign={'left'} mb={'4px'}>
        {label}
      </Typography>
      <TextField
        sx={{
          height: '49px',
          width: { xs: '302px', sm: '392px' },
          backgroundColor,

          borderRadius: '8px',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none',
            },
            '&:hover fieldset': {
              border: 'none',
            },
            '&.Mui-focused fieldset': {
              border: 'none',
            },
            '&.Mui-disabled': {
              '& .MuiInputBase-input': {
                color: '#4B3E3D', // Change text color for disabled state
                WebkitTextFillColor: '#4B3E3D', // Ensures color change on Webkit browsers
              },
            },
          },
          '& .MuiInputBase-input': {
            color: '#4B3E3D', // Change text color
          },
        }}
        value={value}
        disabled
        InputProps={{
          endAdornment: isEditable && (
            <InputAdornment
              onClick={handleEditClick}
              className="cursor-pointer"
              position="start"
            >
              <EditIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default EditTextField;
