import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import QRCode from 'react-qr-code';
import { Box, Typography } from '@mui/material';

function BarcodePopup({ open, handleClose, qrCodeData }) {
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <QRCode value={qrCodeData.barcode} />
          <Box
            display={'flex'}
            gap={1}
            marginTop={'5px'}
            onClick={handleClose}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ cursor: 'pointer' }}
          >
            <ArrowBack />
            <Typography>Back</Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default BarcodePopup;
