import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';

import HorizontalMargin from '../../common/components/HorizontalMargin';
import CustomButton from '../../common/components/CustomButton';
import { useValidatePromoCodeMutation } from '../../services/apis/PromoCodeService';
import { useConfirmOrderMutation } from '../../services/apis/PaymentService';
import {
  addPromoCodeDetails,
  addPaymentIntentId,
  removeItemFromCart,
  addClientSecret,
} from '../../store/slices/CartSlice';
import Colors from '../../theme/KidzCubicleTheme/Colors';
import crossIcon from '../../assets/mi_delete.svg';
import { useGetAccountDetails } from '../../common/hooks/useGetAccountDetails';

const PromoCodeSchema = Yup.object({
  promoCode: Yup.string().required('Please Enter Code'),
});

const initialValues = {
  promoCode: '',
};

const createData = (id, name, Minutes, Price) => {
  return { id, name, Minutes, Price };
};

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    '& $notchedOutline': {
      borderColor: 'black.main',
    },
  },
  focused: {},
  notchedOutline: {},
}));

const OrderCard = ({ paymentMethod = false }) => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const [confirmOrder] = useConfirmOrderMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [promoMinutes, setPromoMinutes] = useState(0);
  const [isPromoCodeValid, setIsPromoCodeValid] = useState(false);

  const outlinedInputClasses = useOutlinedInputStyles();
  const { items, promoCodeDetails } = useSelector((state) => state.cart);
  const { account } = useGetAccountDetails();

  const [validatePromoCode] = useValidatePromoCodeMutation();

  const { values, errors, touched, setFieldValue, handleSubmit, setTouched } =
    useFormik({
      initialValues,
      validationSchema: PromoCodeSchema,
      enableReinitialize: true,
      onSubmit: async () => {
        if (promoMinutes > 0) {
          setPromoMinutes(0);
          setIsPromoCodeValid(false);
          dispatch(addPromoCodeDetails(null));
        } else {
          setPromoMinutes('');
          const response = await validatePromoCode(values.promoCode);
          const packageWithPromo = {};
          if (response?.error?.originalStatus === 200) {
            const minutes = +response?.error?.data?.split(':')[1];
            setIsPromoCodeValid(true);
            setPromoMinutes(minutes);
            packageWithPromo.promoMinutes = minutes;
            packageWithPromo.promoCode = promoCode;
            dispatch(addPromoCodeDetails(packageWithPromo));
          } else {
            setIsPromoCodeValid(false);
            setPromoMinutes(null);
            packageWithPromo.promoMinutes = null;
            packageWithPromo.promoCode = null;
          }
        }
      },
    });

  const isBirthdayPackage = items[0]?.packageName === 'Birthday Party Pack';

  const rows = [
    ...items.map((item) =>
      createData(item.id, item.packageName, item?.minutes, item.price)
    ),
    promoMinutes > 0 && !isBirthdayPackage
      ? createData('promo', 'Promo', promoMinutes, 0)
      : null,
  ].filter((row) => row !== null);

  const { promoCode } = values;

  const totalMinutes = rows.reduce((acc, curr) => acc + curr.Minutes, 0);
  const totalPrice = rows.reduce((acc, curr) => acc + curr.Price, 0);
  const handleNextButtonClick = async () => {
    setIsLoading(true);
    let largestPack = items[0];
    items.forEach((item) => {
      if (item.price > largestPack.price) {
        largestPack = item;
      }
    });

    const packagesNames = items?.map((item) => item?.packageName).join(', ');

    try {
      const resp = await confirmOrder({
        accountId: account.accountId,
        amount: +totalPrice,
        currency: 'GBP',
        packageName: packagesNames,
        packageId: largestPack.id,
        ...(!isBirthdayPackage && {
          minutes: totalMinutes,
        }),
      });
      if (resp.data) {
        dispatch(addPaymentIntentId(resp.data.paymentIntentId));
        dispatch(addClientSecret(resp.data.clientSecret));
        setIsLoading(false);
        navigate('/user/dashboard/payment');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemovePackage = async (packageId) => {
    setIsPromoCodeValid(false);
    setPromoMinutes(0);
    setFieldValue('promoCode', '');
    setTouched({
      promoCode: false,
    });
    dispatch(removeItemFromCart(packageId));
  };

  useEffect(() => {
    if (promoCodeDetails) {
      setIsPromoCodeValid(true);
      setPromoMinutes(promoCodeDetails.promoMinutes || 0);
      setFieldValue('promoCode', promoCodeDetails.promoCode);
    }
  }, [promoCodeDetails, setFieldValue]);

  return (
    <Box
      maxWidth="800px"
      width={paymentMethod ? '100%' : isMobile ? '98%' : '600px'}
      color="black.light"
      my={4}
      gap={0}
      display="flex"
      alignItems="center"
      justifyContent="start"
      flexDirection="column"
      p={2}
      py={1}
      pb={3}
      borderRadius={2}
      sx={{ border: '1px solid', borderColor: 'borderLight.light' }}
    >
      <Box sx={{ width: '100%', margin: '0 auto' }}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: '30%',
                    fontWeight: 800,
                    fontSize: '20px',
                    border: 0,
                    color: Colors.THEME_BLACK,
                  }}
                >
                  Package
                </TableCell>
                {!isBirthdayPackage && (
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: 800,
                      fontSize: '20px',
                      border: 0,
                      color: Colors.THEME_BLACK,
                    }}
                  >
                    Minutes
                  </TableCell>
                )}
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: 800,
                    fontSize: '20px',
                    border: 0,
                    color: Colors.THEME_BLACK,
                  }}
                >
                  Price
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    color: Colors.THEME_BLACK,
                  }}
                >
                  <TableCell
                    sx={{
                      width: '30%',
                      fontWeight: 600,
                      fontSize: '16px',
                      border: 0,
                      color: Colors.THEME_BLACK,
                    }}
                  >
                    {row.name}
                  </TableCell>
                  {!isBirthdayPackage && (
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: 600,
                        border: 0,
                        fontSize: '16px',
                        color: Colors.THEME_BLACK,
                      }}
                    >
                      {row.name === 'Promo' ? '+' : ''}
                      {row.Minutes}
                    </TableCell>
                  )}
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '16px',
                      paddingY: 0,
                      border: 0,
                      height: '30px',
                      color: Colors.THEME_BLACK,
                    }}
                  >
                    £{row.Price}
                  </TableCell>
                  {!paymentMethod && (
                    <div>
                      {row.name !== 'Promo' && (
                        <Box
                          mt="18px"
                          sx={{ cursor: 'pointer', ml: '1rem' }}
                          onClick={() => {
                            handleRemovePackage(row.id);
                          }}
                        >
                          <img
                            src={crossIcon}
                            style={{ marginLeft: '0px' }}
                            alt=""
                          />
                        </Box>
                      )}
                    </div>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <HorizontalMargin />
      <Box sx={{ width: '100%', margin: '0 auto' }}>
        <TableRow
          sx={{
            width: '100%',
            display: 'flex',
            // flexDirection: "row",
            // justifyContent: "space-between",
          }}
        >
          <TableCell
            sx={{
              fontWeight: 600,
              fontSize: '16px',
              border: 0,
              color: Colors.THEME_BLACK,
              maxWidth: '30%',
              width: '100%',
            }}
          >
            Total
          </TableCell>
          {!isBirthdayPackage && (
            <TableCell
              align="center"
              sx={{
                maxWidth: paymentMethod ? '41%' : '34%',
                width: '100%',
                fontWeight: 600,
                fontSize: '16px',
                border: 0,
                color: Colors.THEME_BLACK,
              }}
            >
              {totalMinutes}
            </TableCell>
          )}
          <TableCell
            align="center"
            sx={{
              width: '100%',
              maxWidth:
                isBirthdayPackage && paymentMethod
                  ? '100%'
                  : isBirthdayPackage
                  ? '48%'
                  : paymentMethod
                  ? '29%'
                  : '25%',
              fontWeight: 600,
              fontSize: '16px',
              border: 0,
              color: Colors.THEME_BLACK,
            }}
          >
            £{totalPrice}
          </TableCell>
          {/* {!paymentMethod && (
            <TableCell
              align="right"
              sx={{
                fontWeight: 600,
                fontSize: "16px",
                border: 0,
                color: Colors.THEME_BLACK,
              }}
            />
          )} */}
        </TableRow>
      </Box>
      {!paymentMethod && !isBirthdayPackage && (
        <Box
          sx={{
            width: '95%',
          }}
        >
          <Typography variant="subtitle1" fontWeight={600} color="black.light">
            Enter Promo Code
          </Typography>
          <OutlinedInput
            error={errors.promoCode && touched.promoCode}
            name="promoCode"
            sx={{
              width: '100%',
              marginTop: '8px',
              fontSize: '13px',
              outline: 'none',
              color: '#909090',
              fontWeight: 500,
              backgroundColor: 'textWhite.smoke',
            }}
            placeholder="KZ1234"
            value={promoCode}
            disabled={isPromoCodeValid}
            classes={outlinedInputClasses}
            endAdornment={
              <InputAdornment component="Box">
                {promoMinutes > 0 && isPromoCodeValid && (
                  <CheckCircleOutlineIcon
                    color="success"
                    sx={{ position: 'absolute', left: '80px' }}
                  />
                )}
                {promoMinutes == null && !isPromoCodeValid && (
                  <ErrorIcon
                    sx={{ marginRight: '370px', color: 'richRed.main' }}
                  />
                )}
                <Button
                  disabled={promoCode?.length === 0}
                  sx={{
                    color: 'primary.main',
                    fontWeight: '500',
                    textTransform: 'capitalize',
                    marginLeft: 'auto',
                  }}
                  onClick={handleSubmit}
                >
                  {promoMinutes > 0 ? 'Edit' : 'Apply'}
                </Button>
              </InputAdornment>
            }
            onChange={(e) => {
              const upperCasedValue = e.target.value.toUpperCase();
              if (upperCasedValue.length <= 6)
                setFieldValue('promoCode', upperCasedValue);
            }}
            // onBlur={handleBlur}
          />
          {promoMinutes == null && !isPromoCodeValid && (
            <Typography
              color="richRed.main"
              sx={{
                // marginLeft: "15px",
                marginTop: '10px',
                fontWeight: '600',
              }}
            >
              Invalid Promo code!
            </Typography>
          )}
        </Box>
      )}
      {!paymentMethod && (
        <CustomButton
          variant={'contained'}
          color={'primary'}
          classes="contained-btn"
          sx={{
            color: 'white',
            width: '95%',
            borderRadius: '50px',
            marginTop: '20px !important',
            height: '50px',
            boxShadow: 'none',
            textTransform: 'capitalize',
          }}
          onClick={() => handleNextButtonClick()}
        >
          {!isLoading ? (
            'Next'
          ) : (
            <CircularProgress size={22} color="textWhite" />
          )}
        </CustomButton>
      )}
    </Box>
  );
};

export default OrderCard;
