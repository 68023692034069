import { createTheme } from '@mui/material/styles';
import palette from '../palette';
import { components } from '../components';
const KidzCubicleTheme = createTheme({
  palette: palette,
  typography: {
    fontFamily: '"SF Pro Display", sans-serif',
  },
  components,
});

export default KidzCubicleTheme;
