import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { validateRequired } from '../../common/utils/Validation';
import Title from '../../common/components/Title';

import '../../styles/common/Common.scss';
import './Login.scss';
import SubTitle from '../../common/components/SubTitle';
import Colors from '../../theme/KidzCubicleTheme/Colors';
import SocialSignInLoginBtns from '../SocialSignInLoginBtns';
import Input from '../../common/components/Input';
import { signIn } from 'aws-amplify/auth';
import { verifyReCaptcha } from '../../utils/verifyCaptcha';

const emailValidationRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const signInSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .matches(emailValidationRegex, 'Invalid email')
    .required('Email is required'),

  password: Yup.string().required('Password is required'),
});

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const GOOGLE_SITE_KEY = process.env.REACT_APP_GOOGLE_RE_CAPTCHA_SITE_ID;

  const getErrorMessage = (error) => {
    setErrorMessage(error);
  };

  const loadReCaptcha = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(GOOGLE_SITE_KEY).then((token) => {});
    });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: signInSchema,
    onSubmit: async (values) => {
      setErrorMessage('');
      setIsLoading(true);

      try {
        const freshToken = await window.grecaptcha.execute(GOOGLE_SITE_KEY, {
          action: 'submit',
        });
        const response = await verifyReCaptcha(freshToken);

        if (response?.data.success) {
          signIn({ username: values.email, password: values.password })
            .then(() => {
              navigate('/user/dashboard');
            })
            .catch((error) => {
              setErrorMessage(error.message || JSON.stringify(error));
            });
        } else {
          setErrorMessage('Captcha Verification Failed');
        }
      } catch (error) {
        setErrorMessage('Failed to generate reCAPTCHA token');
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${GOOGLE_SITE_KEY}`;
    script.onload = loadReCaptcha;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GOOGLE_SITE_KEY]);

  return (
    <Grid container className="Login--form-container">
      <Grid className="Login__title" item xs={12} textAlign={'center'}>
        <Title name="Welcome Back!" />
        <Box mt={2}>
          <SubTitle name="We make sure that your child's journey with us is safe, enjoyable, and filled with learning adventures." />
        </Box>
      </Grid>
      <Grid item xs={12} marginTop={3}>
        <SocialSignInLoginBtns OnError={getErrorMessage} />
        <Divider className="Login__Divider">or</Divider>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <Box marginTop={2}>
            <Input
              label="Email"
              type="text"
              name="email"
              placeholder={'xyz@gmail.com'}
              isRequired
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.email}
              error={formik.errors.email}
              noHeight
            />
          </Box>
          <Box marginTop={2}>
            <Input
              className="pr-48"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              name="password"
              placeholder={'************'}
              isRequired
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.password}
              error={formik.errors.password}
              noHeight
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="text"
              disabled={isLoading}
              className="Login__link-to-btn"
              onClick={() => {
                navigate('/forgot-password');
              }}
            >
              Forgot Password?
            </Button>
          </Stack>
          {validateRequired(errorMessage) && (
            <Grid item xs={12} mb={2}>
              <Alert severity="error">{errorMessage}</Alert>
            </Grid>
          )}

          <Button
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
            disabled={isLoading || !formik.isValid}
            className="Common__Login-btn"
            sx={{
              color: 'white',
              marginTop: '8px',
            }}
          >
            {!isLoading ? 'Sign In' : <CircularProgress size={24} />}
          </Button>
        </form>
      </Grid>
      <Grid item xs={12}>
        <Box textAlign={'center'} marginTop={'8px'}>
          <small style={{ color: Colors.INPUT_TEXT_COLOR }}>
            Don’t have an account yet?
            <b
              style={{ color: Colors.PRIMARY, cursor: 'pointer' }}
              onClick={() => navigate('/sign-up')}
            >
              Sign up
            </b>
          </small>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
