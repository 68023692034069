import React, { useState, useEffect } from 'react';
import Layout from '../../../layouts/Layout/Layout';
import { Box, Typography, Button } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import TimeSlotSelector from './TimeSlotSelector';
import CustomCalender from './CustomCalender';
import { primaryButton } from '../styles/styles';
import {
  useLazyGetProfileSessionQuery,
  useCreateBookingMutation,
} from '../../../services/apis/BookingService';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedBirthdayPartyUser,
  setSelectedBirthdayPartyUser,
} from '../../../store/slices/BookingSlice';
import { useNavigate } from 'react-router-dom';
import { setCurrentBookingTab } from '../../../store/slices/BookingSlice';
import { useGetAccountDetails } from '../../../common/hooks/useGetAccountDetails';
import NoTimeSlot from '../NoServiceComponents/NoTimeSlot';

const BookingAfterPayment = () => {
  const [triggerProfile, { error }] = useLazyGetProfileSessionQuery();
  const { account } = useGetAccountDetails();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [createBooking] = useCreateBookingMutation();
  const preSelectedProfile = useSelector(getSelectedBirthdayPartyUser);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [sessionData, setSessionData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    const fetchProfileSessions = async (date) => {
      if (account?.accountId && date) {
        const timeSlots = await triggerProfile({
          accountId: account.accountId,
          dayTypeId: 4,
          activityId: 4,
          bookingDate: date,
        });

        if (timeSlots.data) {
          setSessionData(timeSlots.data.sessions);
        }
      }
    };

    fetchProfileSessions(selectedDate);
  }, [account?.accountId, selectedDate, triggerProfile]);

  const onDateSelection = (date) => {
    const formattedDate = date.format('YYYY-MM-DD');
    setSelectedDate(formattedDate);
    setSessionData(null);
  };

  const handleSelect = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);
  };

  const handleBookingReservation = async () => {
    if (preSelectedProfile && selectedTimeSlot && account?.accountId) {
      const payload = {
        accountId: account.accountId,
        profileId: preSelectedProfile.id,
        sessionId: selectedTimeSlot.sessionId,
        slotsBooked: 1,
        sessionTime: selectedTimeSlot.sessionTime,
        date: selectedDate,
        activityId: 4, // activity Id for birthday pack
      };
      const response = await createBooking(payload);
      if (response.data) {
        dispatch(setSelectedBirthdayPartyUser(null));
        navigate('/user/dashboard/bookings');
        dispatch(setCurrentBookingTab(1));
      }
    }
  };

  return (
    <Layout>
      <Box
        display={'flex !important'}
        flexDirection={'column !important'}
        alignItems={'center !important'}
        justifyContent={'center !important'}
        gap={2}
      >
        <Typography
          fontSize={'2rem !important'}
          color="#4B3E3D !important"
          fontWeight={'700 !important'}
        >
          Book Your Party Slot
        </Typography>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Typography fontSize={'1rem'} fontWeight={'400'} color="#4B3E3D">
            Congratulations on choosing your perfect party package! Let's lock
            in the date and
          </Typography>
          <Typography fontSize={'1rem'} fontWeight={'400'} color="#4B3E3D">
            time for your special celebration:
          </Typography>
        </Box>

        <Box
          display={'flex '}
          alignItems={'top'}
          justifyContent={'center'}
          gap={5}
          sx={{
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
          }}
        >
          <Box>
            <Typography
              color="#4B3E3D !important"
              fontSize={'1.2rem !important'}
              fontWeight={'700 !important'}
              marginBottom={1}
              textAlign={'center'}
              my={'16px !important'}
            >
              Select a Date
            </Typography>
            <CustomCalender
              onlyEnableSunday={true}
              onDateSelection={onDateSelection}
            />
          </Box>
          <Box textAlign={'center'}>
            {sessionData && (
              <TimeSlotSelector
                sessionData={sessionData}
                selectedActivity={'Birthday Party'}
                onSlotSelect={(selectedTime) => handleSelect(selectedTime)}
                selectedDate={selectedDate}
              />
            )}
            {error && !sessionData && (
              <>
                <Typography
                  fontSize={'1.2rem !important'}
                  my={'16px !important'}
                  fontWeight={'700 !important'}
                  color={'#4B3E3D !important'}
                >
                  Choose a Time Slot
                </Typography>
                <NoTimeSlot />
              </>
            )}
          </Box>
        </Box>

        <Button
          variant="contained"
          disabled={!preSelectedProfile || !selectedTimeSlot}
          sx={primaryButton}
          onClick={handleBookingReservation}
        >
          Reserve
        </Button>

        <Typography
          marginTop={'10px'}
          cursor={'pointer'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          gap={1}
          onClick={() => {
            window.history.back();
          }}
        >
          <ArrowBack /> Back
        </Typography>
      </Box>
    </Layout>
  );
};

export default BookingAfterPayment;
