import Colors from './KidzCubicleTheme/Colors';
const palette = {
  primary: {
    main: Colors.PRIMARY,
  },
  inputColor: {
    main: Colors.INPUT_TEXT_COLOR,
  },
  textWhite: {
    main: Colors.WHITE_COLOR,
    smoke: Colors.WHITE_SMOKE,
  },
  lightBlue: {
    main: Colors.LIGHT_BLUE,
  },
  borderLight: {
    main: Colors.LIGHT_BORDER_COLOR,
    light: Colors.LIGHT_GREY_BORDER_COLOR,
    lighter: Colors.GREY_COLOR_2,
  },
  lightGreen: {
    main: Colors.LIGHT_GREEN,
  },

  richRed: {
    main: Colors.RED_COLOR,
  },
  black: {
    main: Colors.JET_BLACK,
    light: Colors.BLACK,
  },
  pink: {
    main: Colors.PINK,
  },
};

export default palette;
