/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Box, Button, Grid, Modal, Slider, Typography } from '@mui/material';
import {
  useGetAllProfilesQuery,
  useUpdateProfileMinutesMutation,
} from '../../services/apis/ProfileService';
import CircularProgress from '@mui/material/CircularProgress';
import ChildCard from '../../common/components/ChildCard';
import { useNavigate } from 'react-router-dom';
import { clearCart } from '../../store/slices/CartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getSelectedBirthdayPartyUser,
  setSelectedBirthdayPartyUser,
} from '../../store/slices/BookingSlice';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetAccountDetails } from '../../common/hooks/useGetAccountDetails';

const roundToNearestTen = (n) => {
  let a = Math.floor(n / 10) * 10;
  let b = a + 10;
  return n - a > b - n ? b : a;
};

const AssignPercentageToChildModal = ({
  open,
  setOpen,
  intentData,
  minutes,
}) => {
  const { amount } = intentData;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { account } = useGetAccountDetails();
  const birthdayPartyChild = useSelector(getSelectedBirthdayPartyUser);
  const [selectedChildIndex, setSelectedChildIndex] = useState(null);
  const [sliderValues, setSliderValues] = useState({});
  const [selectedChild, setSelectedChild] = useState(false);
  const isBirthdayPackSelected = useSelector(
    (state) => state.cart?.items[0]?.packageName
  );
  const packages = useSelector((state) => state.cart?.items);
  function hasBirthdayPackage() {
    return (
      packages.some((p) => p.id === 6) ||
      intentData?.metadata?.packageId === '6'
    );
  }
  const { data } = useGetAllProfilesQuery(account?.accountId, {
    skip: !Boolean(account?.accountId),
  });
  const isMobile = useMediaQuery('(max-width:768px)');
  const [UpdateProfileMinutes, { isLoading, isError }] =
    useUpdateProfileMinutesMutation();

  const totalAllocated = Object.values(sliderValues).reduce(
    (acc, curr) => acc + curr,
    0
  );

  const remainingPercentage = 100 - totalAllocated;

  const handleClose = () => setOpen && setOpen(false);

  const handleSliderChange = async (childId, newValue) => {
    const currentValue = sliderValues[childId] || 0;
    const newChildValue =
      currentValue > 0
        ? newValue - currentValue <= remainingPercentage
          ? newValue
          : currentValue + remainingPercentage
        : newValue;
    if (totalAllocated < 100 || newValue < sliderValues[childId]) {
      const updatedSliderValues = {
        ...sliderValues,
        [childId]: roundToNearestTen(newChildValue),
      };
      setSliderValues(updatedSliderValues);
    }
  };

  const handleChildClick = (index, child) => {
    setSelectedChildIndex(index === selectedChildIndex ? null : index);
    if (index !== selectedChildIndex) {
      setSelectedChild(child);
    } else {
      setSelectedChild(false);
    }
  };

  const handleSubmit = async () => {
    if (!isLoading) {
      try {
        if (hasBirthdayPackage()) {
          dispatch(setSelectedBirthdayPartyUser(selectedChild));
          await UpdateProfileMinutes([
            {
              id: selectedChild?.id,
              minutes: intentData?.description,
            },
          ]).unwrap();
          handleClose();
          await navigate('/user/dashboard/payment/booking');
          dispatch(clearCart());
        } else {
          await UpdateProfileMinutes(
            Object.keys(sliderValues).map((key) => ({
              id: +key,
              minutes: minutes * (sliderValues[key] / 100),
            }))
          ).unwrap();
          navigate('/user/dashboard');
          handleClose();
          dispatch(clearCart());
        }
      } catch (error) {
        toast.error('Failed to allocate minutes!');
      }
    }
  };
  // automaticaly select profile
  useEffect(() => {
    if (birthdayPartyChild) {
      const index = data?.findIndex((obj) => obj.id === birthdayPartyChild.id);
      handleChildClick(index, birthdayPartyChild);
    }
  }, [data, birthdayPartyChild]);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        width="80%"
        maxWidth="814px"
        m="15% auto"
        bgcolor="background.paper"
        borderRadius="5px"
        border="none"
        outline="none"
        px={isMobile ? 2.5 : 4}
        py={isMobile ? 3 : 5}
        flexWrap="wrap"
        alignItems={'start'}
      >
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Box
              color="black"
              gap="10px"
              display={'flex'}
              width="100%"
              justifyContent={'center'}
              flexDirection="column"
              alignItems={'center'}
              height="100%"
            >
              <img src="/add-check.svg" alt="add" />
              <Typography color={'black'} variant="h4" fontWeight="700">
                £ {amount}
              </Typography>
              <Typography variant="h6" fontWeight="600" color="primary">
                Payment Successful
              </Typography>
              {minutes && (
                <Typography fontWeight="500">
                  Total Purchased Minutes: {minutes}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box
              width={'100%'}
              display="flex"
              flexDirection="column"
              alignItems={'baseline'}
            >
              <Typography color="black.light">
                {isBirthdayPackSelected === 'Birthday Party Pack'
                  ? 'Select the Celebrating Profile'
                  : 'Allocate Minutes to Children'}
              </Typography>
              <Box
                maxHeight="200px"
                width="100%"
                mt="0.5rem"
                sx={{ overflowY: 'scroll' }}
                mr={isMobile ? '2px' : '0px'}
              >
                {hasBirthdayPackage() ? (
                  <Box>
                    {data?.map((child, index) => (
                      <Box pb={2}>
                        <ChildCard
                          profileId={child.id}
                          checked={selectedChildIndex === index}
                          onClick={() => handleChildClick(index, child)}
                          key={index}
                          fname={child?.profileFirstName}
                          lname={child?.profileLastName}
                          modal={true}
                          profileCardColor={child?.profileCardColor}
                          sx={{ fontSize: '16px' }}
                        />
                      </Box>
                    ))}
                  </Box>
                ) : (
                  data?.map((child, i) => (
                    <Box
                      key={child.id}
                      width="100%"
                      border="1px solid"
                      borderColor="borderLight.lighter"
                      borderRadius="8px"
                      padding="15px 20px"
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      bgcolor="#fff"
                      mb="20px"
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Typography color="black.light">
                          {`${child?.profileFirstName} ${child?.profileLastName}`}
                        </Typography>
                        <Typography>{sliderValues[child.id] || 0}%</Typography>
                      </Box>
                      <Slider
                        value={sliderValues[child.id] || 0}
                        color="blue"
                        max={100}
                        onChange={(e, newValue) =>
                          handleSliderChange(child.id, newValue)
                        }
                        disabled={
                          remainingPercentage <= 0 && !sliderValues[child.id]
                        }
                      />
                    </Box>
                  ))
                )}
              </Box>
              {isError ? (
                <Typography color={'red'}>Internal Server Error</Typography>
              ) : (
                ''
              )}

              <Button
                disabled={
                  (!hasBirthdayPackage() && remainingPercentage !== 0) ||
                  (hasBirthdayPackage() && selectedChild === false)
                }
                variant="contained"
                color="primary"
                fontWeight="600"
                sx={{
                  borderRadius: '100px',
                  color: 'textWhite.main',
                  textTransform: 'capitalize',
                  width: '100%',
                  height: '48px',
                  fontSize: '18px',
                  marginTop: '1rem !important',
                }}
                onClick={handleSubmit}
              >
                {!isLoading ? (
                  isBirthdayPackSelected === 'Birthday Party Pack' ? (
                    'Reserve the Party Slot'
                  ) : (
                    'Save'
                  )
                ) : (
                  <CircularProgress size={22} color="textWhite" />
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AssignPercentageToChildModal;
