import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentBookingTab: 0,
  IspackageAvailable: true,
  selectedBirthdayPartyUser: null,
  showCartPopup: false,
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setCurrentBookingTab(state, action) {
      state.currentBookingTab = action.payload;
    },
    setPackageAvailable(state, action) {
      state.IspackageAvailable = action.payload;
    },
    setSelectedBirthdayPartyUser(state, action) {
      state.selectedBirthdayPartyUser = action.payload;
    },
    resetBookingSlice(state) {
      state.currentBookingTab = 0;
      state.IspackageAvailable = true;
    },
    setShowCartPopup(state, action) {
      state.showCartPopup = action.payload;
    },
  },
});

export const {
  setCurrentBookingTab,
  setPackageAvailable,
  resetBookingSlice,
  setSelectedBirthdayPartyUser,
  setShowCartPopup,
} = bookingSlice.actions;
export const currentBookingTab = (state) =>
  state.BookingSlice.currentBookingTab;
export const IspackageAvailable = (state) =>
  state.BookingSlice.IspackageAvailable;
export const getSelectedBirthdayPartyUser = (state) =>
  state.BookingSlice.selectedBirthdayPartyUser;
export const getShowCartPopup = (state) => state.BookingSlice.showCartPopup;

export default bookingSlice.reducer;
