import AuthLayout from '../layouts/AuthLayout';
import AUTH_ROUTES from './LoginRoutes';
import USER_ROUTES from './UserRoutes';
import UserLayout from '../layouts/UserLayout';
import { Navigate } from 'react-router-dom';
import '../styles/common/Common.scss';

const Routes = (isAuthenticated) => [
  {
    path: '/user',
    element: isAuthenticated ? <UserLayout /> : <Navigate to={'/login'} />,
    children: [...USER_ROUTES],
  },

  {
    path: '/',
    element: !isAuthenticated ? (
      <AuthLayout />
    ) : (
      <Navigate to={'/user/dashboard'} />
    ),
    children: [...AUTH_ROUTES],
  },
];

export default Routes;
