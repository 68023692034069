export const EMAIL_REG_EXP =
  // eslint-disable-next-line no-control-regex
  /^(((?!.*[^\x00-\x7F])[^<>()[\]\\.,;:\s@"](\.[^<>()[\]\\.,;:\s@"])*){1,64}|(".{1,64}"))@(?:(?:(?!.*[^.]{64,})(?:(?:(?:xn--)?[a-zA-Z\-0-9]+(?:-[a-zA-Z\-0-9]+)*([.]{1,1}))){1,4}([a-zA-Z][a-zA-Z0-9]{1,}))|(?:\[(?:(?:(i|I)(p|P)(v)6:(?:(?:[a-zA-Z\-0-9]{1,4}(?::[a-zA-Z\-0-9]{1,4}){7})|(?:(?!(?:.*[a-zA-Z\-0-9][:\]]){7,})(?:[a-zA-Z\-0-9]{1,4}(?::[a-zA-Z\-0-9]{1,4}){0,5})?::(?:[a-zA-Z\-0-9]{1,4}(?::[a-zA-Z\-0-9]{1,4}){0,5})?)))|(?:(?:(i|I)(p|P)(v)6:(?:(?:[a-zA-Z\-0-9]{1,4}(?::[a-zA-Z\-0-9]{1,4}){5}:)|(?:(?!(?:.*[a-zA-Z\-0-9]:){5,})(?:[a-zA-Z\-0-9]{1,4}(?::[a-zA-Z\-0-9]{1,4}){0,3})?::(?:[a-zA-Z\-0-9]{1,4}(?::[a-zA-Z\-0-9]{1,4}){0,3}:)?)))?(?:(?:25[0-5])|(?:2[0-4][0-9])|(?:1[0-9]{2})|(?:[1-9]?[0-9]))(?:\.(?:(?:25[0-5])|(?:2[0-4][0-9])|(?:1[0-9]{2})|(?:[1-9]?[0-9]))){3}))\]))$/;
const MOBILE_REG_EXP = /^[6-9]\d{9}$/gi;

export const validateMobile = (mobile) => {
  if (mobile?.length < 0) return false;
  const mobileRegExp = new RegExp(MOBILE_REG_EXP);
  return mobileRegExp.test(mobile);
};

export function validateEmail(email) {
  if (email?.length < 0) return false;
  const emailRegExp = new RegExp(EMAIL_REG_EXP);
  return emailRegExp.test(email);
}
export function validateNumber(value) {
  if (!value || isNaN(value)) return false;
  return true;
}

export function validateRequired(value) {
  if (!value || value?.length === 0) return false;
  return true;
}
