import React from 'react';

const EditIcon = ({ height = 18, width = 18 }) => {
  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0561 6.02L11.9761 6.94L2.91609 16H1.99609V15.08L11.0561 6.02ZM14.6561 0C14.4061 0 14.1461 0.1 13.9561 0.29L12.1261 2.12L15.8761 5.87L17.7061 4.04C18.0961 3.65 18.0961 3.02 17.7061 2.63L15.3661 0.29C15.1661 0.09 14.9161 0 14.6561 0ZM11.0561 3.19L-0.00390625 14.25V18H3.74609L14.8061 6.94L11.0561 3.19Z"
        fill="#4B3E3D"
      />
    </svg>
  );
};

export default EditIcon;
