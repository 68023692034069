import React from 'react';
import { Button, Modal, Box, Typography } from '@mui/material';

export const DeleteModal = ({ open, onClose, onDelete }) => {
  const handleDeleteClick = () => {
    onDelete();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="delete-modal-title"
      aria-describedby="delete-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography
          textAlign={'center'}
          id="delete-modal-description"
          sx={{ mt: 2 }}
        >
          Do you really want to cancel reservation?
        </Typography>
        <Box
          sx={{
            mt: 4,
            display: 'flex',
            justifyContent: 'space-between',
            gap: 1,
          }}
        >
          <Button
            onClick={handleDeleteClick}
            variant="contained"
            color="error"
            sx={{
              backgroundColor: '#FF5733',
              color: 'white',
              borderRadius: '50px',
              width: '239px',
              fontSize: '13px',
              mx: 1,
              textTransform: 'none',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={onClose}
            variant="outlined"
            color="error"
            textTransform={'none'}
            sx={{
              color: 'error',
              borderRadius: '50px',
              width: '239px',
              fontSize: '13px',
              textTransform: 'none',
            }}
          >
            No
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
