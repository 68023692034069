import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentOtpStep: 0, // 1. profile detail 2. new number 3. verify otp 4. success
  newNumber: '',
};

const SettingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setCurrentOtpStep(state, action) {
      state.currentOtpStep = action.payload;
    },
    setNewNumberDetail(state, action) {
      state.newNumber = action.payload;
    },
  },
});

export const { setCurrentOtpStep, setNewNumberDetail } = SettingSlice.actions;
export const getCurrentOtpStep = (state) => state.SettingSlice.currentOtpStep;
export const getNewNumber = (state) => state.SettingSlice.newNumber;
export default SettingSlice.reducer;
