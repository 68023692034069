import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';

import Upload from '../../common/components/Upload';
import ChildCard from '../../common/components/ChildCard';
import { useGetAllProfilesQuery } from '../../services/apis/ProfileService';
import ChildSkeleton from '../../common/components/Skeletons/ChildSkeleton';
import QRModal from './QRModal';
import { calculateAge } from '../../utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useGetAccountDetails } from '../../common/hooks/useGetAccountDetails';

import './Dashboard.scss';
import { clearCart } from '../../store/slices/CartSlice';
import { useDispatch } from 'react-redux';

export default function Dashboard() {
  const [activeChildDetail, setActiveChildDetail] = useState(null);
  const { account } = useGetAccountDetails();
  const dispatch = useDispatch();

  const {
    data,
    isLoading,
    refetch: refetchChildren,
    isUninitialized,
  } = useGetAllProfilesQuery(account?.accountId, {
    skip: !Boolean(account?.accountId),
  });

  const activeItems = data?.filter((item) => item.status === 'ACTIVE');

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (!isUninitialized) {
      refetchChildren();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUninitialized]);

  useEffect(() => {
    if (activeItems?.length <= 0) {
      dispatch(clearCart());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItems]);

  if (activeChildDetail) {
    return (
      <>
        {activeChildDetail && (
          <QRModal
            childDetail={activeChildDetail}
            open={activeChildDetail}
            handleClose={() => setActiveChildDetail(null)}
            refetchChildren={refetchChildren}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Box>
        <Box>
          <Grid container>
            {isLoading ? (
              <Grid item xs={12} sm={6} md={6} lg={5}>
                <ChildSkeleton />
              </Grid>
            ) : (
              <Grid
                container
                justifyContent={matches ? 'center' : 'start'}
                columnSpacing={{ xs: 0, sm: 1.5 }}
                rowSpacing={1.5}
              >
                {activeItems?.map((child) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      key={child.id}
                    >
                      <ChildCard
                        profileId={child.id}
                        mins={child.minutes}
                        male={child.gender === 'Male' ? true : false}
                        age={calculateAge(child.dob)}
                        fname={child.profileFirstName}
                        lname={child.profileLastName}
                        profileCardColor={child.profileCardColor}
                        onClick={() => setActiveChildDetail(child)}
                      />
                    </Grid>
                  );
                })}
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Upload />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
