import React from 'react';
import { no_bookings } from '../../../shared/images';

import { Button, Box, Typography } from '@mui/material';
import { primaryButton } from '../styles/styles';
import { setCurrentBookingTab } from '../../../store/slices/BookingSlice';
import { useDispatch } from 'react-redux';
const NoBookings = () => {
  const dispatch = useDispatch();
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <img src={no_bookings} alt="no booking" />
      <Typography variant="h6">You have No Bookings Yet.</Typography>
      <Button
        variant="contained"
        sx={primaryButton}
        onClick={() => dispatch(setCurrentBookingTab(0))}
      >
        Book Now
      </Button>
    </Box>
  );
};

export default NoBookings;
