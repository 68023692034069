import React, { useState } from 'react';
import { Modal, Box, Typography } from '@mui/material';
import { done } from '../../shared/images';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  bgcolor: 'background.paper',
  border: '2px solid white',

  borderRadius: '8px',
  boxShadow: 24,
  px: 8,
  py: 3,
};

const SuccessPopup = ({ message }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={style}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <img alt="done" src={done} />
          <Typography
            margin={'4px'}
            s
            id="modal-title"
            variant="h6"
            fontWeight={'bold'}
            component="h2"
          >
            {message}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default SuccessPopup;
