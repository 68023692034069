import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { combineReducers } from 'redux';

import api from '../services/api';
import CartSlice from './slices/CartSlice';
import BookingSlice from './slices/BookingSlice';
import SettingSlice from './slices/SettingSlice';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart', 'order'],
};

const appReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  cart: CartSlice,
  BookingSlice,
  SettingSlice,
});

const rootReducer = (state, action) => {
  let updatedState = { ...state };
  return appReducer(updatedState, action);
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
});

export const persistor = persistStore(store);
