import React from 'react';
import { no_profile } from '../../../shared/images';
import { Box, Typography } from '@mui/material';

const NoProfile = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <img src={no_profile} alt="no time slot" />
      <Typography> No profiles available for the selected session.</Typography>
    </Box>
  );
};

export default NoProfile;
