import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, TextField } from '@mui/material';
import { ArrowBack } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentOtpStep,
  setNewNumberDetail,
  getNewNumber,
} from '../../../store/slices/SettingSlice';
import { useGetAccountDetails } from '../../../common/hooks/useGetAccountDetails';
import {
  updateUserAttributes,
  sendUserAttributeVerificationCode,
} from 'aws-amplify/auth';
import { CircularProgress } from '@mui/material';
import CountryCodeList from '../../../common/components/CountryCodeList';
import { COUNTRY_CODES } from '../../../common/utils/CountryCodeList';

const NewPhoneNumber = () => {
  const dispatch = useDispatch();
  const reduxNewNumber = useSelector(getNewNumber);
  const [newNumber, setNewNumber] = useState(null);
  const { account } = useGetAccountDetails();
  const [isLoading, setIsLoading] = useState(false);
  const [coountryCodeValue, setCountryCodeValue] = useState({
    value: '+44',
    flag: 'GB',
  });
  const [countryCodeData, setCountryCodeData] = useState(null);

  async function updatePhoneNumber() {
    const phone = newNumber !== null ? newNumber : reduxNewNumber?.phoneNumber;
    const countryCode = countryCodeData?.phone
      ? countryCodeData.phone
      : reduxNewNumber.phone;
    try {
      await updateUserAttributes({
        userAttributes: {
          phone_number: `+${countryCode}${phone}`,
        },
      });
      await sendUserAttributeVerificationCode({
        userAttributeKey: 'phone_number',
      });
    } catch {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }
  const handleClickSave = async () => {
    setIsLoading(true);
    if (newNumber !== null) {
      dispatch(
        setNewNumberDetail({
          code: countryCodeData.code,
          phone: countryCodeData.phone,
          phoneNumber: newNumber,
        })
      );
    }
    await updatePhoneNumber();
    dispatch(setCurrentOtpStep(3));
  };
  const handleBackClick = () => {
    dispatch(setCurrentOtpStep(1));
  };

  const handleCountrySelect = (countryData) => {
    setCountryCodeValue(countryData);
    setCountryCodeData({
      phone: countryData.value.slice(1),
      code: countryData.flag,
    });
  };

  const handleNewNumberChange = (event) => {
    setNewNumber(event.target.value);
  };

  const getCountryCodeFlag = (countryCode) => {
    const country = COUNTRY_CODES?.find(
      (country) => country.value === countryCode
    );
    return country.flag ?? 'GB';
  };
  useEffect(() => {
    if (!Boolean(reduxNewNumber?.phone)) {
      setCountryCodeData({
        phone: account?.phoneNumber.slice(1, 3),
        code: 'gb',
      });
    }
    if (Boolean(account))
      setCountryCodeValue({
        flag: getCountryCodeFlag(account?.phoneNumber.slice(0, 3)),
        value: account?.phoneNumber.slice(0, 3),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={2}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Typography fontSize={'1.8rem'} color={'#4B3E3D'}>
        Phone Number
      </Typography>
      <Box>
        <Typography mb={'2px'}>Current</Typography>
        <TextField
          sx={{
            width: { xs: '302px', sm: '390px' },
            background: '#E9E9E9',

            borderRadius: '8px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
              '&.Mui-disabled': {
                '& .MuiInputBase-input': {
                  color: '#4B3E3D',
                  WebkitTextFillColor: '#4B3E3D',
                },
              },
            },
            '& .MuiInputBase-input': {
              color: '#4B3E3D',
            },
          }}
          value={account?.phoneNumber}
        />
      </Box>
      <Box>
        <Typography mb={'2px'}>Enter New </Typography>
        <Box display={'flex'} gap={1}>
          <Box
            sx={{
              height: '48px',
              width: { sm: '105px' },
              borderRadius: '8px',
              // boxShadow: '0px 0px 0px 1px #1B1F2326',
              // display: 'flex',
              // alignItems: 'center',
              // gap: '5px',
              // padding: '8px',
              // cursor: 'pointer',
            }}
          >
            <CountryCodeList
              label=""
              onSelectChange={handleCountrySelect}
              showLabel={false}
              countryCode={coountryCodeValue}
            />
          </Box>
          <TextField
            type="number"
            sx={{
              width: { xs: '188px', sm: '276px' },
              '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
                {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
            }}
            onChange={handleNewNumberChange}
            value={newNumber !== null ? newNumber : reduxNewNumber?.phoneNumber}
          />
        </Box>
      </Box>

      <Button
        variant="primary"
        sx={{ width: '100%', mt: '20px' }}
        onClick={handleClickSave}
        disabled={
          isLoading || (!Boolean(newNumber) && !Boolean(reduxNewNumber))
        }
      >
        <span>Submit</span>
        {isLoading && (
          <CircularProgress sx={{ marginLeft: 2 }} size={22} color="inherit" />
        )}
      </Button>
      <Box
        className="cursor-pointer"
        display={'flex'}
        gap={1}
        onClick={handleBackClick}
      >
        <ArrowBack />
        <Typography>Back</Typography>
      </Box>
    </Box>
  );
};

export default NewPhoneNumber;
