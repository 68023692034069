// src/services/postsApi.js
import api from '../api';
export const bookingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getActivities: builder.query({
      query: (date) => `/activities/date/${date}`,
    }),
    getProfileSession: builder.query({
      query: (activity) =>
        `/profiles-sessions/${activity.accountId}/${activity.dayTypeId}/${activity.activityId}/${activity.bookingDate}
      `,
    }),

    createBooking: builder.mutation({
      query: (bookingData) => ({
        url: '/bookings',
        method: 'POST',
        body: bookingData,
      }),
    }),
    checkPackageByProfileMinute: builder.mutation({
      query: (profileId) => ({
        url: `/profile/${profileId}`,
        method: 'GET',
      }),
    }),
    deleteBooking: builder.mutation({
      query: (bookingId) => ({
        url: `/bookings/${bookingId}`,
        method: 'DELETE',
      }),
    }),
    getBookingList: builder.mutation({
      query: (accountId) => ({
        url: `/bookings/account/${accountId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyGetActivitiesQuery,
  useLazyGetProfileSessionQuery,
  useCreateBookingMutation,
  useDeleteBookingMutation,
  useCheckPackageByProfileMinuteMutation,
  useGetBookingListMutation,
} = bookingApi;
