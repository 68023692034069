import { fetchUserAttributes } from 'aws-amplify/auth';
import { getUrl } from 'aws-amplify/storage';

export const getProfileImage = (profileId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const attributes = await fetchUserAttributes();
      const sub = attributes.sub;

      const linkToStorageFile = await getUrl({
        path: Boolean(profileId)
          ? `${sub}/${profileId}/profile.jpg`
          : `${sub}/profile.jpg`,
      });
      resolve(linkToStorageFile.url.toString());
    } catch (e) {
      reject(e);
    }
  });
};
