import { Box, Button, Grid, Typography } from '@mui/material';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import InputTitle from '../../../common/components/InputTitle';
import Input from '../../../common/components/Input';
import CountryCodeList from '../../../common/components/CountryCodeList';
import { checkFieldInput } from '../../../utils';
import { useUpdateProfileMutation } from '../../../services/apis/ProfileService';
import { toast } from 'react-toastify';
const formValues = {
  contactNo: '',
  countryCode: { label: 'United Kingdom', value: '+44', flag: '🇬🇧' },
};

const AlternativeContactModal = ({
  setStepNo,
  handleClose,
  childDetail,
  refetchChildren,
}) => {
  const [UpdateProfile] = useUpdateProfileMutation();
  const { alternateEmergencyNo } = childDetail;

  const validationSchema = Yup.object({
    contactNo: Yup.string()
      .required('Please Enter Alternative Contact no.')
      .test(
        'is-10-digits',
        'Alternative Contact no. must be 10 digits',
        (value) => value && value.length === 10
      )
      .test(
        'uk-starts-with-7',
        'UK number must start with 7',
        (value, context) => {
          if (values.countryCode.value === '+44 ') {
            const firstDigit = value.charAt(0);
            return firstDigit === '7';
          }
          return true;
        }
      ),
    countryCode: Yup.object(),
  });

  const { handleSubmit, handleBlur, values, errors, touched, setFieldValue } =
    useFormik({
      initialValues: formValues,
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        const reqBody = { ...childDetail };
        reqBody.alternateEmergencyNo = `${values.countryCode.value}${values.contactNo}`;
        const response = await UpdateProfile(reqBody);
        if (response.data) {
          refetchChildren();
          toast.success('Contact No. updated successfully!', {
            autoClose: 1000,
          });
          handleClose();
        } else if (response.error) {
          toast.error('Failed to update details!');
        }
      },
    });

  return (
    <Box display="flex" flexDirection="column" gap="9px">
      <Typography
        color={'black'}
        fontSize="28px"
        fontWeight="700"
        textAlign="center"
      >
        Alternative Contact Number
      </Typography>
      <form onSubmit={handleSubmit}>
        <Input
          label="Current"
          disabled={true}
          placeholder=""
          type="text"
          name="profileFirstName"
          value={`${alternateEmergencyNo.slice(
            0,
            3
          )} ${alternateEmergencyNo.slice(3, alternateEmergencyNo.length)}`}
          style={{
            backgroundColor: '#ECECEC',
            color: '#4B3E3D',
            fontSize: '16px',
            fontWeight: '400',
          }}
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
          marginTop="-5px"
          mb={1}
        >
          <InputTitle label="Enter New" />
          <Grid container columnSpacing={1}>
            <Grid item xs={4} mt="5px">
              <CountryCodeList
                label=""
                showLabel={false}
                disable={true}
                error={errors.countryCode}
                onSelectChange={(selectedOption) => {
                  setFieldValue('countryCode', {
                    ...selectedOption,
                  });
                }}
                countryCode={values.countryCode}
              />
            </Grid>
            <Grid item xs={8}>
              <Input
                placeholder="123456789"
                name="contactNo"
                value={values.contactNo}
                error={errors.contactNo}
                touched={touched.contactNo}
                handleBlurEvent={handleBlur}
                handleChangeEvent={(e) =>
                  checkFieldInput(e, setFieldValue, /^\d+$/, 10)
                }
              />
            </Grid>
          </Grid>
        </Box>
        <Button
          type="submit"
          disabled={values.contactNo === ''}
          variant="contained"
          sx={{
            backgroundColor: 'primary.main',
            color: 'white',
            py: 1,
            borderRadius: 5,
            width: '100%',
          }}
        >
          Save
        </Button>
        <Box
          color="#4B3E3D"
          pt={2}
          // onClick={handleClose}
          display="flex"
          justifyContent={'center'}
          sx={{ cursor: 'pointer' }}
          onClick={() => setStepNo(1)}
        >
          <Box component="img" mr="10px" src="/back.svg" alt="back"></Box>{' '}
          <span
            style={{ fontSize: '12px', color: '#4B3E3D', fontWeight: '500' }}
          >
            Back
          </span>
        </Box>
      </form>
    </Box>
  );
};

export default AlternativeContactModal;
