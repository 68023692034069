import { Box, Button, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import Input from '../../../common/components/Input';
import { checkFieldInput } from '../../../utils';
import { useUpdateProfileMutation } from '../../../services/apis/ProfileService';

const form1Values = {
  firstAidInfo: '',
};

const validationSchema = Yup.object({
  firstAidInfo: Yup.string()
    .required('Please Enter New First Aid Info')
    .min(2, 'should be atleast two digits'),
});

const FirstAidInfo = ({
  setStepNo,
  childDetail,
  refetchChildren,
  handleClose,
}) => {
  const [UpdateProfile] = useUpdateProfileMutation();

  const { firstAidInfo } = childDetail;

  const { handleSubmit, handleBlur, values, errors, touched, setFieldValue } =
    useFormik({
      initialValues: form1Values,
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        const reqBody = {
          ...childDetail,
          firstAidInfo: values.firstAidInfo,
        };
        const response = await UpdateProfile(reqBody);
        if (response.data) {
          refetchChildren();
          toast.success('First aid info updated successfully!', {
            autoClose: 1000,
          });
          handleClose();
        } else if (response.error) {
          toast.error('Failed to update details!');
        }
      },
    });

  return (
    <Box display="flex" flexDirection="column" gap="9px">
      <Typography
        color={'black.light'}
        fontSize="28px"
        fontWeight="700"
        textAlign="center"
      >
        First Aid Information
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <Input
            label="Current"
            disabled
            placeholder=""
            type="text"
            name="firstAidInfo"
            style={{
              backgroundColor: '#ECECEC',
              color: '#4B3E3D',
              fontSize: '16px',
              fontWeight: '400',
            }}
            value={firstAidInfo}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Enter New"
            placeholder="Enter new info"
            type="text"
            name="firstAidInfo"
            handleChangeEvent={(e) => {
              checkFieldInput(e, setFieldValue);
            }}
            style={{ fontSize: '16px' }}
            onBlur={handleBlur}
            value={values.firstAidInfo}
            error={errors.firstAidInfo}
            touched={touched.firstAidInfo}
          />
        </Grid>
        <Grid xs={12} mt={1}>
          <Button
            type="submit"
            disabled={values.firstAidInfo.length === 0}
            variant="contained"
            sx={{
              backgroundColor: 'primary.main',
              color: 'white',
              py: 1,
              borderRadius: 5,
              width: '100%',
            }}
          >
            Save
          </Button>
        </Grid>
        <Box
          color="#4B3E3D"
          pt={2}
          display="flex"
          justifyContent={'center'}
          sx={{ cursor: 'pointer' }}
          onClick={() => setStepNo(1)}
        >
          <Box component="img" mr="10px" src="/back.svg" alt="back"></Box>{' '}
          <span
            style={{ fontSize: '12px', color: '#4B3E3D', fontWeight: '500' }}
          >
            Back
          </span>
        </Box>
      </form>
    </Box>
  );
};

export default FirstAidInfo;
