export const COUNTRY_CODES = [
  { label: 'United States', value: '+1', flag: '🇺🇸' },
  { label: 'United Kingdom', value: '+44', flag: '🇬🇧' },
  { label: 'India', value: '+91', flag: '🇮🇳' },
  { label: 'Germany', value: '+49', flag: '🇩🇪' },
  { label: 'France', value: '+33', flag: '🇫🇷' },
  { label: 'China', value: '+86', flag: '🇨🇳' },
  { label: 'Japan', value: '+81', flag: '🇯🇵' },
  { label: 'Australia', value: '+61', flag: '🇦🇺' },
  { label: 'Canada', value: '+1', flag: '🇨🇦' },
  { label: 'Brazil', value: '+55', flag: '🇧🇷' },
  { label: 'Mexico', value: '+52', flag: '🇲🇽' },
  { label: 'Spain', value: '+34', flag: '🇪🇸' },
  { label: 'Italy', value: '+39', flag: '🇮🇹' },
  { label: 'Russia', value: '+7', flag: '🇷🇺' },
  { label: 'South Korea', value: '+82', flag: '🇰🇷' },
  { label: 'Netherlands', value: '+31', flag: '🇳🇱' },
  { label: 'Turkey', value: '+90', flag: '🇹🇷' },
  { label: 'Switzerland', value: '+41', flag: '🇨🇭' },
  { label: 'Sweden', value: '+46', flag: '🇸🇪' },
  { label: 'Norway', value: '+47', flag: '🇳🇴' },
  { label: 'Denmark', value: '+45', flag: '🇩🇰' },
  { label: 'Finland', value: '+358', flag: '🇫🇮' },
  { label: 'Poland', value: '+48', flag: '🇵🇱' },
  { label: 'Belgium', value: '+32', flag: '🇧🇪' },
  { label: 'Austria', value: '+43', flag: '🇦🇹' },
  { label: 'Greece', value: '+30', flag: '🇬🇷' },
  { label: 'Portugal', value: '+351', flag: '🇵🇹' },
  { label: 'Ireland', value: '+353', flag: '🇮🇪' },
  { label: 'Czech Republic', value: '+420', flag: '🇨🇿' },
  { label: 'Hungary', value: '+36', flag: '🇭🇺' },
  { label: 'Romania', value: '+40', flag: '🇷🇴' },
  { label: 'Argentina', value: '+54', flag: '🇦🇷' },
  { label: 'Chile', value: '+56', flag: '🇨🇱' },
  { label: 'Colombia', value: '+57', flag: '🇨🇴' },
  { label: 'Peru', value: '+51', flag: '🇵🇪' },
  { label: 'Venezuela', value: '+58', flag: '🇻🇪' },
  { label: 'Ecuador', value: '+593', flag: '🇪🇨' },
  { label: 'Bolivia', value: '+591', flag: '🇧🇴' },
  { label: 'Uruguay', value: '+598', flag: '🇺🇾' },
  { label: 'Paraguay', value: '+595', flag: '🇵🇾' },
  { label: 'New Zealand', value: '+64', flag: '🇳🇿' },
  { label: 'South Africa', value: '+27', flag: '🇿🇦' },
  { label: 'Egypt', value: '+20', flag: '🇪🇬' },
  { label: 'Saudi Arabia', value: '+966', flag: '🇸🇦' },
  { label: 'United Arab Emirates', value: '+971', flag: '🇦🇪' },
  { label: 'Qatar', value: '+974', flag: '🇶🇦' },
  { label: 'Kuwait', value: '+965', flag: '🇰🇼' },
  { label: 'Singapore', value: '+65', flag: '🇸🇬' },
  { label: 'Malaysia', value: '+60', flag: '🇲🇾' },
  { label: 'Thailand', value: '+66', flag: '🇹🇭' },
  { label: 'Indonesia', value: '+62', flag: '🇮🇩' },
  { label: 'Philippines', value: '+63', flag: '🇵🇭' },
  { label: 'Vietnam', value: '+84', flag: '🇻🇳' },
];
