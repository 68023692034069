import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import OrderDetailCard from './OrderCard';
import Layout from '../../layouts/Layout/Layout';
import { ArrowBack } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';

const Order = () => {
  const cart = useSelector((state) => state.cart);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Layout>
      <Box
        mt={4}
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={2}
        mx={'auto'}
      >
        <Typography variant="h4" fontWeight={700} sx={{ color: 'black.light' }}>
          Order Details
        </Typography>
        {cart?.items?.length > 0 ? (
          <OrderDetailCard />
        ) : (
          <Typography variant="h6">Cart is empty</Typography>
        )}
      </Box>
      <Box
        className="cursor-pointer"
        display={'flex'}
        justifyContent={'center'}
        onClick={handleBackClick}
      >
        <ArrowBack />
        <Typography>Back</Typography>
      </Box>
    </Layout>
  );
};

export default Order;
