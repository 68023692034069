import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import '../styles/style.scss';

dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  weekStart: 1,
});

function getNextSunday() {
  const today = dayjs();
  const dayOfWeek = today.day();
  const daysUntilNextSunday = 7 - dayOfWeek;
  const nextSunday = today.add(daysUntilNextSunday, 'day');

  return nextSunday;
}

export default function CustomCalender({ onDateSelection, onlyEnableSunday }) {
  const today = dayjs();
  const [selectedDate, setSelectedDate] = React.useState(today);
  const calendarRef = useRef();
  const [calendarHeight, setCalendarHeight] = React.useState('438px');

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onDateSelection(date);
  };

  // disabled eslint because hook will trigger infinite api call
  useEffect(() => {
    if (onlyEnableSunday) {
      const nextSunday = getNextSunday();
      setSelectedDate(nextSunday);
      onDateSelection(nextSunday);
    } else {
      onDateSelection(today);
    }
    getHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dayOfWeekFormatter = (date) => {
    const weekdayShort = dayjs(date).locale('en').format('dd');
    return (
      weekdayShort.charAt(0).toUpperCase() + weekdayShort.slice(1).toLowerCase()
    );
  };

  const getHeight = () => {
    setTimeout(() => {
      const lastWeekElement = calendarRef.current?.querySelector(
        '.MuiDayCalendar-weekContainer:last-child'
      );

      if (lastWeekElement) {
        const lastAriaRowIndex = parseInt(
          lastWeekElement.getAttribute('aria-rowindex'),
          10
        );
        const height = lastAriaRowIndex > 5 ? '495px' : '438px';
        setCalendarHeight(height);
      }
    }, 0);
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      paddingBottom={'5px'}
      sx={{
        justifyContent: {
          lg: 'flex-start',
          sm: 'center',
        },
      }}
    >
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        dateFormats={{ monthShort: 'MMM' }}
      >
        <DateCalendar
          onMonthChange={() => getHeight()}
          ref={calendarRef}
          sx={{
            padding: 2,
            '&.MuiDateCalendar-root': {
              height: calendarHeight,
              maxHeight: '550px',
            },
          }}
          minDate={today}
          value={selectedDate}
          onChange={(date) => handleDateChange(date)}
          dayOfWeekFormatter={dayOfWeekFormatter}
          showDaysOutsideCurrentMonth
          autoFocus={false}
          slotProps={{ calendarHeader: { format: 'MMM YYYY' } }}
          shouldDisableDate={(day) => {
            const date = day.toDate();

            return onlyEnableSunday && date.getDay() !== 0;
          }}
        />
      </LocalizationProvider>
    </Box>
  );
}
