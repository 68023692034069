import { Typography, Box } from '@mui/material';

import icon from '../../assets/packages/tick-circle.svg';

const Bullet = ({ text, sx }) => {
  return (
    <Box mt={2} display="flex" alignItems="center">
      <img src={icon} alt="tick" />

      <Typography
        ml={1}
        fontSize={'13px'}
        color={'black.light'}
        fontFamily={'SF Pro Display'}
        fontWeight={500}
        sx={sx}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default Bullet;
