import React from 'react';
import Modal from '../../../common/components/Modal';
import ProfileDetails from './ProfileDetails';
import NewPhoneNumber from './NewPhoneNumber';
import VerifyOtp from './VerifyOtp';
import { getCurrentOtpStep } from '../../../store/slices/SettingSlice';
import { useSelector } from 'react-redux';
import SuccessPopup from '../../../common/components/SuccessPopup';
const ViewProfile = ({ openModal, onClose }) => {
  const currentOtpStep = useSelector(getCurrentOtpStep);

  return (
    <>
      {currentOtpStep === 1 && (
        <Modal
          open={openModal}
          children={<ProfileDetails />}
          handleClose={onClose}
          sx={{
            width: {
              xs: '342px',
              sm: '472px',
            },
          }}
        />
      )}
      {currentOtpStep === 2 && (
        <Modal
          open={openModal}
          children={<NewPhoneNumber />}
          handleClose={onClose}
          sx={{
            width: {
              xs: '342px',
              sm: '472px',
            },
          }}
        />
      )}
      {currentOtpStep === 3 && (
        <Modal
          open={openModal}
          children={<VerifyOtp />}
          handleClose={onClose}
          sx={{
            width: {
              xs: '342px',
              sm: '472px',
            },
          }}
        />
      )}
      {currentOtpStep === 4 && <SuccessPopup message={'Number updated!'} />}
    </>
  );
};

export default ViewProfile;
