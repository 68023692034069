import React from 'react';

const DeleteIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 2C5.5 1.46957 5.71071 0.960859 6.08579 0.585786C6.46086 0.210714 6.96957 0 7.5 0H13.5C14.0304 0 14.5391 0.210714 14.9142 0.585786C15.2893 0.960859 15.5 1.46957 15.5 2V4H19.5C19.7652 4 20.0196 4.10536 20.2071 4.29289C20.3946 4.48043 20.5 4.73478 20.5 5C20.5 5.26522 20.3946 5.51957 20.2071 5.70711C20.0196 5.89464 19.7652 6 19.5 6H18.431L17.564 18.142C17.5281 18.6466 17.3023 19.1188 16.9321 19.4636C16.5619 19.8083 16.0749 20 15.569 20H5.43C4.92414 20 4.43707 19.8083 4.06688 19.4636C3.6967 19.1188 3.47092 18.6466 3.435 18.142L2.57 6H1.5C1.23478 6 0.98043 5.89464 0.792893 5.70711C0.605357 5.51957 0.5 5.26522 0.5 5C0.5 4.73478 0.605357 4.48043 0.792893 4.29289C0.98043 4.10536 1.23478 4 1.5 4H5.5V2ZM7.5 4H13.5V2H7.5V4ZM4.574 6L5.431 18H15.57L16.427 6H4.574ZM8.5 8C8.76522 8 9.01957 8.10536 9.20711 8.29289C9.39464 8.48043 9.5 8.73478 9.5 9V15C9.5 15.2652 9.39464 15.5196 9.20711 15.7071C9.01957 15.8946 8.76522 16 8.5 16C8.23478 16 7.98043 15.8946 7.79289 15.7071C7.60536 15.5196 7.5 15.2652 7.5 15V9C7.5 8.73478 7.60536 8.48043 7.79289 8.29289C7.98043 8.10536 8.23478 8 8.5 8ZM12.5 8C12.7652 8 13.0196 8.10536 13.2071 8.29289C13.3946 8.48043 13.5 8.73478 13.5 9V15C13.5 15.2652 13.3946 15.5196 13.2071 15.7071C13.0196 15.8946 12.7652 16 12.5 16C12.2348 16 11.9804 15.8946 11.7929 15.7071C11.6054 15.5196 11.5 15.2652 11.5 15V9C11.5 8.73478 11.6054 8.48043 11.7929 8.29289C11.9804 8.10536 12.2348 8 12.5 8Z"
        fill="#FF0000"
      />
    </svg>
  );
};

export default DeleteIcon;
